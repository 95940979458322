@import 'ThemeProvider/mixins.scss';
$itemPadding: 16px;

.root {
  *,
  h4 {
    font-size: 14px;
  }
  .fixedHeader {
    position: sticky;
    width: 100%;
    top: 0;
    background: white;
    box-shadow: 0 -6px 10px 5px rgba(0, 0, 0, 0.5);
  }

  .header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    /*padding: 1rem 1rem;
        border-bottom: 1px solid #dee2e6;*/
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    background-color: white;
  }

  .withHeaderInner {
    padding: 10px;
    font-size: 16px;
  }

  .body.padding {
    padding: $itemPadding;
  }

  .title {
    margin-bottom: 0;
    line-height: 1.5;
  }

  .iccLogo {
    width: 100%;
    max-width: 175px;

    img {
      vertical-align: middle;
      border-style: none;
    }
  }

  .close {
    background-color: transparent;
    border: 0;
    position: absolute;
    right: 5px;
    top: 5px;

    span {
      font-size: 1.5rem;
      font-weight: 700;
    }

    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
    color: gray;

    &:hover {
      @include themeMod(color, activeColor);
    }
  }

  .close:not(:disabled),
  .close:not(:disabled) {
    cursor: pointer;
  }
}

.section {
  h4 {
    @include themeMod(color, navMenuHeaderColor);
    padding-bottom: $itemPadding;
    padding-left: $itemPadding;
  }

  .list > * {
    margin: 0px;
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: $itemPadding;
    font-size: 14px;

    div {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  hr {
    margin-left: $itemPadding;
    margin-right: $itemPadding;
  }

  .list > :global(.IcoIssue-link-selected) {
    @include themeMod(color, navMenuLinkSelectedColor);

    > svg {
      @include themeMod(color, navMenuLinkSelectedColor);
    }

    background: aliceblue;
  }
}
