.root {
  position: relative;
  display: flex;
  align-items: flex-end;
}

.root.normal {
  background: gray;
  width: 100%;
}

/*.root.normal .inputLabel, .root.normal .submitBtn {
    border-radius: 0px;
}*/

.root > .inputFormControl {
  margin-right: 10px;
  height: auto;
}

/*.root .submitBtn {
position: absolute;
bottom: 0px;
}*/

.root.normal .submitBtn {
  position: static;
}

.disabled {
  pointer-events: none;
}

.inputLabel {
  cursor: default;
}
