@import '../ThemeProvider/ColorDefinitions.module.scss';
@import '../ThemeProvider/mixins.scss';

.header,
.descriptionContainer {
  position: relative;
}

.root {
  width: 100%;
}

.titleLabel {
  margin-bottom: 5px;
}

.nameContainer textArea {
  font-weight: bold;
}

.descriptionTextArea {
  resize: none;
  width: 100%;
}

.statusEditor {
  position: absolute;
  right: 0px;
  top: -5px;
}

.severity {
  position: relative;
  padding-left: 20px;

  > span {
    display: inline-block;
    vertical-align: middle;
  }

  > .severityColor {
    position: absolute;
    left: 0px;
    top: 4px;
    width: 16px;
    height: 16px;
    border-color: rgba(0, 0, 0, 0.1);
    border-style: solid;
    border-width: 1px;
  }
}

.titleAndDescriptionSectionHeader {
  font-size: 1rem;
  color: $royalDarkBlue;
}

.sectionHeader {
  font-size: 1rem;
  color: $royalDarkBlue;
  cursor: pointer;
}

.showOnMapColumn.showOnMapColumn.showOnMapColumn {
  width: 7rem;
  @media (max-width: 768px) {
    width: 6rem;
  }
}
.titleColumn.titleColumn.titleColumn {
  width: 15rem;
  @media (max-width: 768px) {
    width: 4rem;
  }
}
.createdColumn.createdColumn.createdColumn {
  width: 8rem;
  @media (max-width: 768px) {
    width: 5rem;
  }
}
.sourceColumn.sourceColumn.sourceColumn {
  width: 6rem;
  @media (max-width: 768px) {
    width: 3rem;
  }
}
.deleteColumn.deleteColumn.deleteColumn {
  width: 4rem;
  @media (max-width: 768px) {
    width: 3rem;
  }
}

.iconWrapper.iconWrapper svg {
  color: $danger;
  cursor: pointer;
}
.iconWrapper svg:hover {
  color: darken($danger, 20%);
}
