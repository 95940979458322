.viewport {
  overflow: auto;
  position: relative;
  height: 100%;
}

.list {
  display: block;
  padding-inline-start: 0;
  padding-left: 0;
}
