@import '../ThemeProvider/mixins.scss';

.root {
  z-index: 20;
  background: white;
  top: 0px;
  padding-top: 30px;
  padding-bottom: 0px;
  position: absolute;
  height: 100%;
  width: 100%;

  .chatList {
    border-style: solid;
    height: 100%;
    overflow: auto;
  }

  .addChatIcon {
    cursor: pointer;
    font-size: 18px;
    @include themeMod(color, primaryIconColor);

    &:hover {
      @include themeMod(color, primaryIconColor);
    }
  }

  :global(.MuiListItem-root) {
    cursor: pointer;

    &.itemHeader {
      font-weight: bold;

      &:hover {
        background: inherit !important;
        cursor: default !important;
      }

      .itemHeaderTitle {
        flex-grow: 1;
      }
    }

    &:hover {
      @include themeMod(background, activeBackgroundColor);

      .countLabel {
        @include themeMod(background, activeColor);
      }
    }
  }

  :global(.MuiListItem-root.Mui-selected) .countLabel {
    @include themeMod(background, activeColor);
  }
}
