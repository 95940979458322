@import '../ThemeProvider/ColorDefinitions.module.scss';

.root {
  text-align: center;
  white-space: pre-wrap;
  color: $primary-icon-color;
  font-weight: bold;
  width: 100%;
}

.root.center {
  position: absolute;
  height: 100%;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  z-index: 1;
}

.body {
  text-align: center;
  width: 100%;
}

.primary {
  font-size: 20px;
  margin-bottom: 40px;
}

.secondary {
}
