.root {
  > table,
  > :global(.MuiTable-root) {
    table-layout: fixed;
  }

  .row {
    display: flex;
    th,
    td,
    th {
      display: block;
    }
  }

  .cell {
    padding: 16px;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }

  th,
  td,
  th > *,
  td > *,
  .cell,
  .cell > *,
  :global(.MuiTableCell-root > *),
  :global(.MuiTableCell-root) {
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: normal;
    white-space: nowrap;
    width: 100%;
    text-align: left;
    vertical-align: unset;
    position: relative;
  }

  .multiLine,
  .multiLine > * {
    text-overflow: unset;
    overflow: visible;
    word-wrap: normal;
    white-space: normal;
    word-break: break-word;
  }

  &.wrap th,
  &.wrap td,
  &.wrap th > *,
  &.wrap td > *,
  &.wrap cell,
  &.wrap cell > *,
  &.wrap :global(.MuiTableCell-root > *),
  &.wrap :global(.MuiTableCell-root),
  .wrapCell,
  .wrapCell > * {
    word-wrap: break-word;
    white-space: normal;
  }

  th:nth-child(1),
  td:nth-child(1),
  :global(.MuiTableCell-root:nth-child(1)),
  .cell:nth-child(1) {
    width: 36%;
  }

  th:nth-child(2),
  td:nth-child(2),
  :global(.MuiTableCell-root:nth-child(2)),
  .cell:nth-child(2) {
    width: 30%;
  }

  th:nth-child(3),
  td:nth-child(3),
  :global(.MuiTableCell-root:nth-child(3)),
  .cell:nth-child(3) {
    width: 34%;
  }
}
