.styleDelete {
  color: var(--icc-red) !important;
}
.styleAdd {
  color: var(--icc-green) !important;
}
.italic {
  font-style: italic;
}
.small {
  font-size: smaller;
}
.textLink {
  color: var(--icc-blue) !important;
}
.halfWidth {
  width: 50%;
}
