.root {
  background: #3998ea;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.root > * {
  color: white;
}

.myDropDownContainer > * {
  padding-top: 10px;
  width: 100%;
  /*padding-bottom: 5px;*/
}

.myDateContainer {
  max-height: 60px;
  /*padding-bottom: 5px;*/
  top: 0px;
}

.myHrContainer {
  /*max-height: 50px;*/
  /*padding-bottom: 5px;*/
  top: 0px;
}

.myHr {
  border-top-color: rgb(23, 162, 184);
  border-top-width: 1px;
}

.filterLabel {
  font-weight: 900;
}

@media (max-width: 768px) {
  .root .myDateContainer {
    top: 0px;
    float: none;
    max-width: 100%;
  }
}
