.root {
  list-style: none;
  display: block;
  margin-bottom: 5px;
  /*padding-inline-end: .5rem;
    padding-inline-start: .5rem;*/
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  background: #fff;
  border-bottom: 1px dotted #474747;
}

.rootSearch {
  list-style: none;
  display: block;
  margin-bottom: 5px;
  /*padding-inline-end: .5rem;
    padding-inline-start: .5rem;*/
  border-style: solid;
  border-width: 1px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 5px;
}

.header {
  position: relative;
  margin-bottom: 7px;
  font-size: 14px;
}

.user {
  /*font-weight: bold;*/
  padding-right: 160px;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: normal;
  overflow: hidden;
  font-size: 12px;
}

.createdDate {
  position: absolute;
  top: 0;
  right: 0;
  color: gray;
  font-size: 12px;
}

.message {
  word-break: break-word;
  font-size: 16px;
  font-weight: normal !important;
}

.artifactPreview {
  padding-bottom: 5px;
}
