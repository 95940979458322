.ListItem {
  width: 100%;
  -webkit-align-items: center;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  height: 100%;
  display: -webkit-flex;
  display: flex;
}

.BouncingListItem {
  transition: transform 0.5s ease-out;
  width: 100%;
  -webkit-align-items: center;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  height: 100%;
  display: -webkit-flex;
  display: flex;
}

.Background {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-align-items: center;
  align-items: center;
  padding-right: 16px;
  color: #000;
  background-color: #c2c2c2;
  box-sizing: border-box;
  margin-right: 0px;
}

.Wrapper {
  position: relative;
  transition: max-height 0.5s ease;
  max-height: 1000px;
  transform-origin: top;
  overflow: hidden;
  width: 100%;
  z-index: 1;
}
