@import 'mixins.scss';
@import '_buttons.scss';
@import '_listItem.scss';

.overflow-visible {
  overflow: visible !important;
}

/********Base**********/
.Ico_Issue_ThemeProvider {
  height: inherit;
  width: inherit;
  /*******Fonts*****/
  * {
    font-family: Lato, Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  }

  .MuiTypography-h4,
  .MuiTypography-h2 {
    font-weight: 500 !important;
  }

  .MuiSnackbarContent-message,
  .MuiSnackbarContent-action {
    color: white !important;
  }

  #rootBody .MuiTypography-body2,
  #rootBody .MuiTypography-body2 .btn-link {
    font-size: 0.8rem;
  }

  .btn-link {
    text-decoration: none;
  }
  /********Headers******/
  h1 {
    font-size: 40px;
  }

  h2,
  .MuiTypography-h2 {
    font-size: 20px !important;
  }

  h3,
  h4 {
    margin-bottom: 0px;
    font-size: 18px;
  }
  /********Body********/
  #Ico_Issue_rootBody {
    padding-top: 0px;
    position: static;
  }

  #Ico_Issue_root,
  #Ico_Issue_rootBody,
  #Ico_Issue_masterLayout,
  #Ico_Issue_startUp {
    min-height: 100%;
    height: 100%;
  }

  #Ico_Issue_rootBody .MuiFab-root:hover {
    text-decoration: none;
    background-color: #4798cb;
  }
  /*****Buttons****/
  .MuiButtonBase-root:focus {
    outline: none !important;
  }
  /**Utility classes**/
  .clearfix::after {
    display: block;
    clear: both;
    content: '';
  }

  .single-line-text {
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: normal;
    white-space: nowrap;
  }
  /*****Virtual Grids*******/
  .ReactVirtualized__Grid.ReactVirtualized__List {
    outline-style: none;
  }

  .react-lazylog.ReactVirtualized__Grid.ReactVirtualized__List {
    background-color: transparent;
  }
  /******Snackbar*****/
  .MuiSnackbar-root {
    z-index: 99999 !important;
  }

  .MuiListItem-dense {
    padding-bottom: 0px;
    padding-top: 0px;
  }

  .MuiDialogTitle-root {
    padding-bottom: 0px;
  }

  .MuiRadio-colorPrimary.Mui-checked,
  .MuiSwitch-colorPrimary.Mui-checked,
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: $btn-color-primary;
  }

  .MuiCheckbox-colorSecondary.Mui-checked,
  .MuiRadio-colorSecondary.Mui-checked,
  .MuiSwitch-colorSecondary.Mui-checked {
    color: $btn-color-secondary;
  }

  .MuiSelect-select:focus {
    background-color: none;
  }
  .MuiInputBase-multiline {
    padding: 0;
  }
  .MuiFormLabel-root.Mui-focused {
    color: $primary-dark;
  }
  .MuiInput-underline:after {
    border-bottom: 2px solid $primary-dark;
  }
}

/**********By Theme****************/
/****Body****/
#Ico_Issue_root,
#Ico_Issue_rootBody,
#Ico_Issue_masterLayout,
#Ico_Issue_startUp {
  @include theme(background-color, backgroundColor);
}

@include buttons;
@include listItem(true, true);

/***Icons****/
.svg-inline--fa {
  @include theme(color, fontColor);
}

/***Fonts***/
h1, h2, /*h4, .MuiTypography-h4,*/ .MuiTypography-h2, .ReactVirtualized__Grid.ReactVirtualized__List,
.MuiPaper-root, .MuiDialogContent-root {
  @include theme(color, fontColor);
}

a[aria-disabled='true'],
a[aria-disabled='true'] > img {
  @include theme(color, linkDisabledFontColor);
  text-decoration: none;
  cursor: default;
}

/****Loaders******/
.MuiCircularProgress-colorPrimary {
  @include theme(color, progressColor);
}

/*******Chips******/
.MuiChip-root {
  font-size: 12.8px !important;
}

.MuiChip-root.MuiChip-colorPrimary {
  @include theme(background-color, primaryIconColor);
}
