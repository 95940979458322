@import '../ThemeProvider/mixins.scss';
@import '../ThemeProvider/ColorDefinitions.module.scss';

.header {
  text-decoration: underline;
  font-weight: bold;
}

.listItem {
  cursor: pointer;
}

.list {
  overflow: visible;
}
.linkColor.linkColor {
  color: $primary-blue;
}
.nameColumn.nameColumn.nameColumn {
  width: 25%;
  max-width: 3.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
  padding: 0.5rem;
}
.responsibleColumn.responsibleColumn.responsibleColumn {
  width: 25%;
  max-width: 3.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0.5rem;
}
.lastNotifiedColumn.lastNotifiedColumn.lastNotifiedColumn {
  width: 25%;
  max-width: 3.5rem;
  padding: 0.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
}
.actionsColumn.actionsColumn.actionsColumn {
  width: 25%;
  max-width: 4rem;
  padding: 0.5rem;
}

.deleteButton.deleteButton.deleteButton {
  border: none;
  color: $danger;
  cursor: pointer;
  font-size: 14px;
  padding: 0.25rem;
  text-transform: uppercase;
  &:hover {
    background-color: $lightGray;
    color: $danger !important;
  }
  &:focus {
    box-shadow: none;
  }
}
.editButton.editButton.editButton {
  border: none;
  cursor: pointer;
  font-size: 14px;
  padding: 0.25rem;
  text-transform: uppercase;
  &:hover {
    background-color: $lightGray;
  }
  &:focus {
    box-shadow: none;
  }
}
