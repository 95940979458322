@import '../ThemeProvider/mixins.scss';

.root {
  display: flex;
  flex-direction: row;
  height: 100%;
  padding-bottom: 10px;
  padding-right: 10px;
}

.root.stacked {
  flex-direction: column;
  width: 100%;

  .panel {
    width: 100%;
  }
}

.panel {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 33%;

  .contentContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .header {
      position: relative;
      width: 100%;
    }

    .body {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;

      > :global(.MuiPaper-root) {
        width: 100%;
        height: 100%;
        overflow: hidden;
      }

      .panelContent {
        height: 100%;
        position: relative;
        overflow: hidden;
      }
    }

    &.overflowYhidden .panelContent {
      overflow-y: hidden;
    }

    &.overflowYauto .panelContent {
      overflow-y: auto;
    }

    &.overflowYvisible .panelContent {
      overflow-y: visible;
    }
  }
  /****Default panel widths ***/
  &:nth-child(1) {
    width: 15%;
    min-width: 380px;
  }

  &:nth-child(2) {
    width: 26.5%;
  }

  &.fillWidth {
    flex-grow: 1;
  }

  &.collapsible {
    -webkit-transition: width 0.25s;
    -moz-transition: width 0.25s;
    -o-transition: width 0.25s;
    -ms-transition: width 0.25s;
    transition: width 0.25s;
  }

  .collapsibleTogglerContainer {
    display: flex;
    flex-direction: column;

    .collapsibleToggler {
      color: #fbfbfb;
      @include themeMod(background, headerSecondaryColor);
      text-align: right;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      cursor: pointer;
      width: 100%;
      /*.collapsibleTogglerIcon {
                    font-size: 1.9rem;
                }*/
    }

    .collapsibleContent {
      height: 100%;
      position: relative;
      overflow: auto;
      overflow-x: hidden;
    }
  }

  &.collapsed {
    width: 100px !important;
  }

  &.collapsibleAnimating {
    overflow: hidden;
    white-space: nowrap;
  }
}

.panelHeader {
  width: 100%;
  position: relative;
  padding-bottom: 12px;
  padding-top: 12px;
  display: flex;

  .panelHeaderExtras {
    position: relative;
  }
}
