@import 'ColorDefinitions.module.scss';
@import '../ThemeProvider/mixins.scss';
@mixin buttons {
  .btn-link {
    padding: 0px;
  }

  .btn.btn-link svg.svg-inline--fa {
    color: inherit;
  }

  a svg.svg-inline--fa {
    @include theme(color, inherit);
  }

  .btn {
    border-radius: 0px;

    &.btn-primary {
      background-color: $btn-color-primary;
      border-color: $btn-color-primary;

      &:hover:not(:disabled),
      &:active:not(:disabled) {
        background-color: $btn-color-primaryDark;
        border-color: $btn-color-primaryDark;
      }
    }
    &.btn-outline-primary {
      color: $primary-default;
      border-color: $primary-default;

      &:hover:not(:disabled),
      &:active:not(:disabled) {
        color: $primary-dark;
        border-color: $primary-dark;
        background-color: transparent;
      }
    }

    &.btn-primaryDark {
      color: white;
      background-color: $royalDarkBlue;
      border-color: $royalDarkBlue;

      &:hover:not(:disabled),
      &:active:not(:disabled) {
        background-color: $royalDarkBlueActive;
        border-color: $royalDarkBlueActive;
      }
    }
    &.btn-outline-primaryDark {
      color: $primary-darker;
      border-color: $primary-darker;

      &:hover:not(:disabled),
      &:active:not(:disabled) {
        color: $primary-dark;
        border-color: $primary-dark;
        background-color: transparent;
      }
    }

    &.btn-secondary {
      background-color: $btn-color-secondary;
      border-color: $btn-color-secondary;

      &:hover:not(:disabled),
      &:active:not(:disabled) {
        background-color: $btn-color-secondaryDark;
        border-color: $btn-color-secondaryDark;
      }
    }

    &.btn-danger {
      background-color: $danger;
      border-color: $danger;

      &:hover:not(:disabled),
      &:active:not(:disabled) {
        background-color: darken($danger, 20%);
        border-color: darken($danger, 20%);
      }
    }
    &.btn-outline-danger {
      color: $danger-default;
      border-color: $danger-default;

      &:hover:not(:disabled),
      &:active:not(:disabled) {
        color: $danger-darker;
        border-color: $danger-darker;
        background-color: transparent;
      }
    }

    &.btn-darker {
      color: $background-lighter;
      background-color: $background-darker;
      border-color: $background-darker;

      &:hover:not(:disabled),
      &:active:not(:disabled) {
        background-color: $background-dark;
        border-color: $background-dark;
      }
    }
    &.btn-outline-darker {
      color: $background-darker;
      border-color: $background-darker;

      &:hover:not(:disabled),
      &:active:not(:disabled) {
        color: $background-dark;
        border-color: $background-dark;
        background-color: transparent;
      }
    }

    &.btn-clear {
      background-color: transparent;
      border-color: transparent;
      box-shadow: none;

      &:hover:not(:disabled),
      &:active:not(:disabled) {
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
      }
    }
  }

  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    @include themeMod(color, buttonFontColor);
    background-color: #f9c93a;
    border-color: #f9c93a;
  }

  .btn-secondary:not(:disabled):not(.disabled):active:focus,
  .btn-secondary:focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 130, 32, 0.5);
  }

  .btn-clear:not(:disabled):not(.disabled):active,
  .btn-clear:not(:disabled):not(.disabled).active,
  .show > .btn-clear.dropdown-toggle {
    background-color: transparent;
    border-color: transparent;
  }

  .btn-clear:not(:disabled):not(.disabled):active:focus,
  .btn-clear:focus {
    box-shadow: none;
  }

  .btn-group-lg > .btn,
  .btn-lg {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
  }
  /**All Floating button primary should use primary color**/
  .MuiFab-primary {
    @include theme(color, buttonFontColor);
    @include theme(background-color, primaryButtonColor);
  }

  .MuiFab-primary:hover {
    @include theme(background-color, primaryButtonColor);
  }

  a,
  .btn-link {
    @include theme(color, linkFontColor);

    &:hover {
      @include theme(color, linkHoverFontColor);
    }
  }

  .btn-link {
    text-align: left;
  }
}
