@import '../ThemeProvider/mixins.scss';

.root {
  .removeTrigger:global(.svg-inline--fa) {
    position: absolute;
    right: 10px;
    top: 15px;
    z-index: 4;
    display: none;
    color: gray;
    cursor: pointer;

    &:active,
    &:hover {
      color: $primary-blue;
    }
  }

  &.hasValue .removeTrigger {
    display: inline;
  }

  .input {
    padding-right: 30px;
    height: 48px;
    border-radius: 0px;

    &.compact,
    &.compact:global(.form-control) {
      height: 38px;
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }

  svg.searchIcon {
    @include themeMod(color, primaryIconColor);
    opacity: 0.75;
  }
}
