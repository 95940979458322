@import '../ThemeProvider/mixins.scss';

svg.root:global(.svg-inline--fa) {
  cursor: pointer;
  @include themeMod(color, primaryIconColor);
  opacity: 0.75;
  font-size: 24px;
}

.filterBody {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

.drawer > :global(.MuiPaper-root.MuiDrawer-paper) {
  max-width: 80%;
}
