@import '../ThemeProvider/variables.scss';
@import '../ThemeProvider/ColorDefinitions.module.scss';

.header {
  padding-bottom: 0;
  background-color: $background-white;
  display: flex;
  align-items: center;
  z-index: 3;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
    rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
}
.header h1 {
  padding: 0;
  margin: 0;
  font-size: 2rem;
}

.headerText {
  color: $text-dark;
}
.opsSyncDefault {
  color: $opsSync-default;
}

.root {
  background-color: $background-lighter;

  footer {
    background: $background-dark;
    border-bottom: none;
    min-height: 2rem;
    padding: 0.1rem;
    a,
    a:visited {
      color: white;
      font-size: $fontSizeMd;
    }

    a:hover {
      color: #ddd;
    }

    img {
      width: 2rem;
    }
  }

  .footerLight {
    background: $background-white;

    a,
    a:visited {
      color: $text-dark;
    }

    a:hover {
      color: $text-grey;
    }
  }
}
