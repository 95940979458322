@import '../ThemeProvider/variables.scss';
@import '../ThemeProvider/ColorDefinitions.module.scss';
@import '../Shared/TableFixed.module.scss';
@import '../ThemeProvider/mixins.scss';

.styleDynamicForm {
  border: 1px solid lightgrey;
}

.styleDynamicFormSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // background-color: $royalDarkBlue;
  outline-color: $royalDarkBlue;
  outline-width: 1px;
  outline-style: solid;
  color: $royalDarkBlue;
  width: 90%;
  padding: 0.5rem 0.5rem;
  font-size: 16px;
}
.styleDynamicFormSectionLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // background-color: $royalDarkBlue;
  //outline-color: $royalDarkBlue;
  //outline-width: 1px;
  //outline-style: solid;
  //color: $royalDarkBlue;
  width: 100%;
  //padding: 0.5rem 0.5rem;
  font-size: 16px;
}
.buttonStyledAsLink {
  color: $primary-blue;
  //text-decoration: underline;
  //text-decoration-color: $primary-blue;
  cursor: pointer;
  &:hover {
    color: $primary-blue;
  }
}

.styleDynamicFormHeadingNotSubmitted {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #bfbfbf;
  width: 100%;

  // color: white;
  padding: 1rem 1rem;
  font-size: 20px;
}

.styleDynamicFormHeading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: $royalDarkBlue;
  color: white;
  padding: 1rem 1rem;
  font-size: 24px;
  width: 100%;
}

.styleDynamicFormFooter {
  border-top: 1px solid #777;
  padding-left: 15px;
}

.styleDynamicSection {
  padding-left: 2rem;
}
.styleDynamicSection label {
  margin-bottom: 0;
}
.styleIndented {
  padding-left: 30px;
}
.topToolLine {
  flex-direction: row;
  align-content: space-between;
  margin-bottom: 1rem;
}
.topToolBox {
  width: 60px;
  float: left;
  //background-color: #efefff;
}
.styleIndentedBox {
  padding-left: 30px;
  margin-left: 30px;
  //border: 1px solid black;
  margin: 4px;
}
.styleGrayBox {
  color: #3f3f3f;
  background-color: #dfdfdf;
}
.styleIndentedGrayBox {
  //padding-left: 30px;
  color: #3f3f3f;
  background-color: #dfdfdf;
  margin: 4px;
}
.styleDynamicField {
  padding-bottom: 15px;
}
.styleTopBottomPad div {
  // margin-top: 0.25rem;
  margin-bottom: 0.75rem;
}
.lightText {
  color: $labelGrey;
}
.warning {
  border: 2px solid #ffff00;
  margin: 4px;
}
.absent {
  color: $labelGrey;
}
.present {
  color: $labelGrey;
  background-color: #77ff77;
  border: 1px solid black;
}
.uploadButton {
  color: $white;
  background: $royalDarkBlue;
  font-size: '75%';
}
.styleDelete {
  color: var(--icc-red) !important;
}
.styleAdd {
  color: var(--icc-green) !important;
}
.almostFullWidth {
  width: 80%;
}
.halfWidth {
  width: 50%;
}
.smallWidth {
  width: 10rem;
}
.tinyWidth {
  width: 5rem;
}
.mediumWidth {
  width: 20rem;
}

.styleOptionButton {
  color: white;
  background-color: #5f5f5f !important;
  border: 1px solid black !important;
  margin: 2px !important;
  padding: 2px !important;
}

.inputbold input {
  font-weight: bolder !important;
  // font-size: larger !important;
}
.textfield div::before,
.textfield div::after {
  // this is to allow us to set the border bottom for  MUI  in the section below
  border-bottom: none !important;
}
.textfield textarea {
  // this requires the section above to make it work
  padding: 0.5em;
  box-shadow: none !important;
  border-bottom: 1px solid var(--icc-blue) !important;
  &:focus {
    background-color: $required-input-bg-color;
  }
}
.textfield input {
  // this requires the section above to make it work
  padding: 0.5em;
  box-shadow: none !important;
  border-bottom: 1px solid var(--icc-blue) !important;
  &:focus {
    background-color: $required-input-bg-color;
  }
}

.dfBold {
  font-weight: bold;
}

.dfItalic {
  font-style: italic;
}

.dfSmall {
  font-size: smaller;
}

.dfLarge {
  font-size: larger;
}

.iframeStyleContainer {
  position: relative;
  height: 100%;
  width: 100%;
}

.iframeStyle {
  height: 100%;
  width: 100%;
  z-index: 2;
  position: relative;
}

.loadingBackDrop {
  background: transparent;
  opacity: 1;
}
.btnBack {
  position: relative;
  z-index: 2;
  float: left;
}
.floatLeft {
  float: left;
}
.floatRight {
  float: right;
}
body {
  margin: 0; // 1
  text-align: left; // 3
}

@media screen and (max-width: 599px) {
}

.flexInputs {
  display: flex;
  flex-direction: row;
  // align-items: center;
  // justify-content: space-between;
}

.flexInputItem {
  flex: 1;
}
