@import '../ThemeProvider/variables.scss';
@import '../ThemeProvider/ColorDefinitions.module.scss';

.descriptionFields > *,
.communicationFields > * {
  margin-bottom: 5px;
}

.descriptionFields,
.communicationFields {
  margin-top: 10px;
}

.countrySelect.readOnly > :global.MuiFormControl-root .Mui-disabled {
  color: inherit;
  cursor: text;
}

.countrySelect.readOnly
  > :global.MuiFormControl-root
  .MuiInputBase-root.Mui-disabled {
  cursor: text;
}

.phoneInput.readOnly {
  color: transparent;
}

.phoneLink {
  bottom: 4px;
  position: absolute;
  z-index: 2;
}

.membersDialog {
  composes: dialog from '../Shared/dialogMinimums.module.scss';

  > :global(.MuiDialog-container),
  > :global(.MuiDialog-container > .MuiPaper-root) {
    position: relative;
    height: 100%;
    /*min-width: 90%;
        max-width: 900px;*/
  }
}

.membersDialogContent {
  composes: membersDialogContent from '../Shared/dialogMinimums.module.scss';
}

.largeIcon {
  font-size: 24px !important;
  color: $primary-blue;
  cursor: pointer;
  &:hover {
    color: darken($primary-blue, 20%);
  }
}

.mediumIcon {
  font-size: 16px !important;
  color: $primary-blue;
  cursor: pointer;
  &:hover {
    color: darken($primary-blue, 20%);
  }
}

.iconWrapper.iconWrapper svg {
  color: $primary-blue;
  cursor: pointer;
  &:hover {
    color: darken($primary-blue, 20%);
  }
}
// .iconWrapper.iconWrapper:hover > svg {
//   color: darken($primary-blue, 20%);
// }

// .iconWrapper.iconWrapper.msteamsPurple:hover > svg {
//   color: darken($primary-blue, 20%);
// }

.iconWrapper.iconWrapper.goldColor svg {
  color: $secondary-orange;
  cursor: pointer;
  &:hover {
    color: darken($secondary-orange, 20%);
  }
}
// .iconWrapper.iconWrapper:hover > svg {
//   color: darken($primary-blue, 20%);
// }

.playbooksBtn :global(svg) {
  font-size: 18px;
}

.actionDescription {
  font-size: $fontSizeMd;
  color: gray;
}

.actionBtnContainer {
  composes: mt-2 from global;
  display: inline-block;
  width: 100%;
  text-align: center;
}

.blueColor {
  color: $primary-blue;
}
.colorGold {
  color: $secondary-orange;
}
.colorRed {
  color: $danger;
}

.buttonStyledAsLink {
  background: none;
  border: none;
  padding: 0;
  color: $midLightBlack;
  font-weight: 500;
  text-decoration: underline;
  text-decoration-color: $midLightBlack;
  cursor: pointer;

  &:hover {
    color: $primary-blue;
  }
}
.buttonStyledAsLinkClicked {
  color: #663366;

  &:hover {
    color: darken($color: #663366, $amount: 20);
  }
}
.lastNotified {
  font-size: $fontSizeMd;
  color: gray;
  word-wrap: break-word;
}

.sectionNumber {
  color: $primary-blue;
  font-size: 1.5rem;
  font-weight: 500;
}

.sectionTitle {
  font-size: 1rem;
  font-weight: 400;
}
