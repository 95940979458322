@import '../ThemeProvider/ColorDefinitions.module.scss';
@import '../ThemeProvider/mixins.scss';

.icon {
  width: 24px;
  height: 24px;
}
.erSelection {
  width: 600px;
}
.erTitle {
  width: 300px;
}
.iconWrapper.iconWrapper svg {
  color: $primary-blue;
  cursor: pointer;
  &:hover {
    color: darken($primary-blue, 20%);
  }
}
