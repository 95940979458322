.root {
  composes: p-2 from global;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  > * {
    margin-right: 8px !important;
  }
}
.toolbarDialogIcon {
  color: var(--icc-blue) !important;
}

.popperMenuIconContainer {
  width: 30px;
  min-width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
