@import '../ThemeProvider/ColorDefinitions.module.scss';

.textfield textarea {
  padding: 0.5em;
  &:focus {
    background-color: $required-input-bg-color;
  }
}
.textfield input {
  padding-left: 0.25em;
  &:focus {
    background-color: $required-input-bg-color;
  }
}
