@import '../ThemeProvider/mixins.scss';

.root {
  .statusIcon,
  .actionsContainer,
  .status {
    align-self: baseline;
  }

  .statusIcon {
    width: 32px;
    height: 32px;
    min-width: 32px;
    display: inline-block;
    text-align: center;
  }

  .chatName {
    word-break: break-all;
    flex-grow: 1;
  }

  .actionsContainer {
    margin-right: 16px;
    flex-shrink: 0;

    svg {
      @include themeMod(color, primaryIconColor);
      margin-left: 6px;
    }
  }
}
