@import '../ThemeProvider/variables.scss';
@import '../ThemeProvider/ColorDefinitions.module.scss';
@import '../Shared/TableFixed.module.scss';
@import '../ThemeProvider/mixins.scss';

.tblPivot th,
.tblPivot td,
.tblPivot th > *,
.tblPivot td > *,
.tblPivot .cell,
.tblPivot .cell > * {
  //:global(.MuiTableCell-root > *),
  //:global(.MuiTableCell-root) {
  font-size: 14px !important;
  text-overflow: initial !important;
  overflow: auto !important;
  white-space: inherit !important;
  width: 100% !important;
  text-align: left !important;
  vertical-align: unset !important;
  position: relative !important;
}

.buttonStyledAsLink {
  color: $primary-blue;
  //text-decoration: underline;
  //text-decoration-color: $primary-blue;
  cursor: pointer;

  &:hover {
    color: $primary-blue;
  }
}

.styleNoBorder td {
  border-bottom: none !important;
  padding-bottom: 0;
}

.styleNoBorderNoPadding td {
  border-bottom: none !important;
  padding-top: 0;
  padding-bottom: 0;
}

.styleThreeLinks {
  display: flex;
  justify-content: space-evenly;
}

.styleSubmitterBlock {
  text-align: right;
}

.styleSmallSubmitterBlock {
  font-size: 12px;
}

.stylePictureFrame {
  padding: 5px;
}

.stylePreviousEntryText {
  border: 1px solid #000;
  padding: 5px;
  fill: #bfbfbf;
  color: #000;
  background-color: #bfbfbf;
}
.stylePivotGrid th {
  border: 1px solid #777;
  padding: 2px;
  background-color: #ddd;
}
.stylePivotGrid td {
  border: 1px solid #777;
  padding: 2px;
}
.stylePivotGrid tr {
  border: 1px solid #777;
  padding: 2px;
}
.styleVertAlignTop td {
  vertical-align: top;
}
.addFormClass input[type='text'],
.addFormClass textarea {
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  border-radius: 0 !important;
  outline: none !important;
  width: 98% !important;
  padding: 0.5em 0 0.25em 0.5em !important;
  margin-bottom: 0.25em !important;
  font-size: 16px !important;
  border: 0px solid transparent !important;
  border-style: none !important;
  border-bottom: 1px solid #3e91c5 !important;
  box-shadow: none !important;
  transition: all 0.3s !important;
}
.addFormClass input[type='text']:focus,
.addFormClass textarea:focus {
  background-color: cornsilk;
  border: 0 !important;
  border-bottom: 1px solid #3e91c5 !important;
}

.addFormClass [type='submit'] {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-radius: 0px !important;
  cursor: pointer !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  background-color: #3e91c5 !important;
  border-color: #3e91c5 !important;
  position: relative !important;
  z-index: 2 !important;
  color: #fff !important;
  padding: 0.375rem 0.75rem;
  border: 1px solid transparent !important;
}
.txtAreaShading {
  border: 0.25px solid #d5d5d5;
  padding: 5px;
  background-color: #e3eff6;
}
.priorExecBriefing {
  margin-left: 20px;
  padding: 5px;
}

@media screen and (max-width: 767px) {
  .formMobileView {
    position: relative !important;
    width: 97.5% !important;
    /* margin: 0.25rem; */
    margin: 0 auto !important;
  }
  .hideFromMobile {
    display: none;
  }
}

.iccLogo {
  width: 100%;
  max-width: 175px;
  img {
    color: white;
    vertical-align: middle;
    border-style: none;
  }
}
.lightText {
  color: $labelGrey;
}
