@import '../ThemeProvider/variables.scss';
@import '../ThemeProvider/ColorDefinitions.module.scss';
@import '../Shared/TableFixed.module.scss';

.buttonStyledAsLink {
  color: $primary-blue;
  //text-decoration: underline;
  //text-decoration-color: $primary-blue;
  float: right;
  cursor: pointer;

  &:hover {
    color: $primary-blue;
  }
}
