@import '../ThemeProvider/ColorDefinitions.module.scss';

.root {
  padding-top: 5px;
  height: 100%;

  .exportBtns {
    position: absolute;
    left: 140px;
    top: 5px;
  }
}

.reportContentViewer {
  composes: w-100 mh-100 h-100 from global;
}

.reportResultBody {
  height: 100%;
}

.reportResultBody > iframe {
  margin: 0px;
  padding: 0px;
  border: none;
  width: 100%;
  height: 100%;
}

.reportSelectorContainer {
  position: absolute;
  width: 100%;
  padding: 10px;
  padding-top: 0px;
  padding-right: 5px;
  z-index: 2;
}

.reportSelector {
  width: 100%;
  padding: 5px;
}

.reportSelector,
.reportSelector select {
  width: 100%;
}

.issueSummaryFeed {
  padding-top: 55px;
}

.loadingContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.reportResultHeader {
  > span {
    float: left;
    margin-right: 20px;
  }

  > div {
    float: right;
    position: relative;
    top: -10px;
  }
}

.reportSelectLabel:global(.MuiFormLabel-root) {
  color: $primary-blue;
}

.stakeholderClickHere {
  vertical-align: baseline;
}
