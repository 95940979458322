.viewport {
  overflow: auto;
  position: relative;
  height: 100%;
  padding: 5px;
}

.list {
  display: block;
  padding-inline-start: 0;
  padding-left: 0;
}

.header {
  background: #949494;
  color: white;
  padding: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
  align-items: baseline;

  .name {
    font-weight: bold;
    padding-right: 5px;
  }

  .lockIcon {
  }
}
