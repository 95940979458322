@import '../ThemeProvider/mixins.scss';
@import '../ThemeProvider/ColorDefinitions.module.scss';

.tabHeaders {
  width: 50%;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  padding: 1rem 1.5rem;
  font-weight: 700;
  line-height: 24px;
  font-family: 'Lato', sans-serif !important;
  color: black;
}

.form {
  display: flex;
  flex-direction: column;
  width: 70%;
  min-width: 500px;
  max-width: 700px;
  align-content: flex-start;
  margin: 1rem auto;
  background-color: $background-bluegrey-light;

  h5 {
    font-size: 1.25rem;
    font-weight: 400;
    margin-bottom: 0;
  }
  .forgotPassword {
    font-size: 0.8rem;
  }
  input {
    background-color: $background-white;
    &:focus {
      background-color: $required-input-bg-color;
      border-bottom: 2px solid #203864;
    }
  }
  span {
    text-transform: uppercase;
  }
}
.formBackground {
  background-color: $background-bluegrey-light;
}
.formContent {
  padding: 2rem 2.5rem;
}

.unClickedTabHeader {
  color: $primary-dark;
  background-color: $background-lighter;
}
.requiredIcon {
  color: red;
}
.pwRules {
  font-size: 80%;
  color: $primary-blue;
  margin-left: 1rem;
  cursor: pointer;
}
.helpPhone {
  font-size: 70%;
}
