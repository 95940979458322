.root {
  composes: p-2 from global;

  svg {
    cursor: pointer;
  }
}

.treeItemGroup,
.treeItemSubGroup,
.treeItem {
  composes: pl-3 single-line-text from global;
}

.treeItemGroup:nth-child(1) {
  padding-left: 0px !important;
  overflow: auto;
  display: inline-block;
  padding-right: 8px;
}

.treeItemGroupLabel {
  font-weight: bold;
}

.treeItemLabel {
  font-weight: normal;
}
