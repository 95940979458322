.root {
  position: relative;
  height: 100%;
}

.standardApp .viewHeader {
  padding-left: 45px !important;
  top: -5px !important;
  padding-right: 35px !important;
}

.navClickArea {
  left: 45px;
}

.uploadProgress {
  margin-top: 0px;
  right: 0px;
  position: fixed !important;
}

.overflowYhidden {
  overflow-y: hidden;
}

.overflowYauto {
  overflow-y: auto;
}

.overflowYvisible {
  overflow-y: visible;
}
