@import '../ThemeProvider/mixins.scss';
.root {
  > :global.MuiDivider-root {
    /*background-color: #d9e2ec;*/
    background-color: #dbe3ed;
  }

  svg.icon {
    @include themeMod(color, fontColor);
    min-width: 24px;
    max-width: 24px;
  }
}

.flexColumnStandard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.verticalLine {
  width: 0px;
  height: 50%;
  border: solid 1px $midLightBlack;
}
.verticalLineInvisible {
  width: 0px;
  height: 50%;
  border: solid 1px transparent;
}
.spacerSmall {
  height: 1px;
  width: 1px;
  display: block;
  background: transparent;
  margin: 0;
  padding: 0;
}
.spacerMedium {
  height: 4px;
  width: 4px;
  display: block;
  background: transparent;
  margin: 0;
  padding: 0;
}
.spacerLarge {
  height: 8px;
  width: 8px;
  display: block;
  background: transparent;
  margin: 0;
  padding: 0;
}
.fadedFont {
  color: lighten($midLightBlack, 20%);
  font-size: 12px;
}
