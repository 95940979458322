.root {
  position: relative;
  width: 100%;
  z-index: 1;

  /*:global(.MuiFormControl-root .MuiInput-formControl) {
        margin-top: 16px;
    }*/
}

.actions {
  position: relative;
  width: 100%;
}

.content textarea {
  font-weight: bold;
}

.root .actions > * {
  padding-right: 10px;
}

.root .assignContainer {
  width: 50%;
  float: left;
  top: 7px;
}

.root .dueDateContainer {
  width: 50%;
  top: 7px;
  position: relative;
  float: left;
  margin-right: none;
  padding-right: 10px;
}

.root .dueDateContainer > * {
  margin-top: 0px;
}

@media (max-width: 768px) {
  .root .assignContainer,
  .root .dueDateContainer {
    float: none;
    width: 100%;
    max-width: 100%;
  }

  .root .dueDateContainer > * {
    margin-top: 6px;
  }
}
