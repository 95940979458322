.selectionListCompact {
  border-bottom-style: solid;
  border-width: 1px;
}

.selectionListCompact div[class^='MuiInputBase-root']:before {
  border-bottom-style: none;
  cursor: pointer;
}

.toolbar {
  display: flex;
  composes: p-2 from global;

  > * {
    margin-right: 4px;
  }
}
