@import 'mixins.scss';

.action,
.action.important {
  @include transition(opacity 0.6s);
  cursor: pointer;
  position: relative;
  z-index: 20;

  &:hover {
    opacity: 0.6;
  }
}

.action {
  @include themeMod(color, menuTiggerColor);
}
.action.important {
  @include themeMod(color, menuTiggerColor, null, !important);
}
