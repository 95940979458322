.root {
  display: inline-block;
}

.root > *:first-child {
  display: inherit;
  cursor: pointer;
}

.root.disabled > * {
  cursor: default;
}

.uploadInput {
  display: none;
}

.standard {
  display: block;
  border-style: solid;
  padding: 4px;
  border-width: 1px;
  border-radius: 4px;
  border-color: gray;
}
