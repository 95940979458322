@import '../ThemeProvider/mixins.scss';
@import '../ThemeProvider/ColorDefinitions.module.scss';

.addButton {
  height: 44px;
  width: 44px;
  border-radius: 50%;
  padding: 10px;
  text-align: center;
  cursor: pointer;

  svg.addButtonPlus {
    font-size: 24px;
    @include themeMod(color, buttonFontColor);
  }

  &.small {
    height: 35px;
    width: 35px;
    min-height: unset;
  }

  &.normal {
    cursor: pointer;
  }

  &.small svg.addButtonPlus {
    font-size: 16px;
    @include themeMod(color, buttonFontColor);
  }
}

.mainPageBackBtn {
  color: #fbfbfb !important;
  @include themeMod(background-color, headerSecondaryColor);
  text-align: right;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  cursor: pointer;
  width: 100%;
  align-self: center;
  margin-top: 3px;
}

.button:global(.btn) {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  &.rounded {
    border-radius: 6px;
  }

  &.default,
  &.standard {
    text-transform: capitalize;
    @include transition(color 0.5s);
    color: gray;
    border-color: gray;
    background: white;

    &:hover:not([disabled]) {
      @include themeMod(border-color, activeColor);
      @include themeMod(color, activeColor);

      svg {
        @include themeMod(color, activeColor);
      }
    }
  }

  svg {
    margin-right: 5px;
    color: inherit;
  }

  .rightSpacer {
    margin-right: 0px;
  }
}
