@import '../ThemeProvider/mixins.scss';

.pane {
  padding-bottom: 0;
  position: relative;
  width: 100%;
}

.title {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  word-break: 'break-word';
  font-weight: 'bold';
  padding-right: 15px;
  font-weight: 500;
}

.description {
  font-size: 0.9rem;
  display: 'block';
}

.level {
  font-size: 0.7rem;
  font-weight: 500;
  text-align: center;
  border-bottom-style: solid;
  border-bottom-width: 6px;
  max-width: 100px;
  font-weight: normal;
  min-width: 40px;
  height: 27px;
}
.trend {
  font-weight: 500;
  font-size: 0.7rem;
}

dateTime {
  font-size: 0.7rem;
  font-weight: 500;
}

.dateSegment {
  background-color: lighten($color: $primary-blue, $amount: 42%);
  padding: 4px 6px;
  font-size: 0.7rem;
}

.lastActivity {
  float: left;
}

.typeDescr {
  float: right;
  padding-right: 10px;
  padding-left: 10px;
  font-weight: bold;
}

.responsible {
  > label {
    margin-left: 0px;
    font-weight: bold;
  }

  > span {
    word-break: break-all;
  }
}

.label {
  font-size: 0.7rem;
  color: $labelGrey;
}

.orgName {
  font-size: 0.7rem;
  color: $labelGrey;
}
