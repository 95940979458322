@import '../ThemeProvider/mixins.scss';
@import '../ThemeProvider/ColorDefinitions.module.scss';

.AdminConsolePage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .MuiButton-root {
    border-radius: 0px !important;
  }
}

.AdminConsole {
  background-color: white;
  position: fixed;
  width: 70%;
  top: 100px;

  @media (max-width: 959px) {
    top: 40px;
  }
  min-width: 350px;
  min-height: 500px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding-bottom: 30px;
  margin-bottom: 10px;
  bottom: 10px;
  overflow-y: auto;
}

.HoldingStatementSelectionItem {
  width: 230px;
  font-size: 14px;
  cursor: pointer;
  padding: 10px;
}
.HoldingStatementSelectionItem:hover {
  background-color: rgb(64, 148, 195);
}
.OrganizationInnerContainerEditHeader {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}

.OrphanedIssueEditContainerHeader {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  border-bottom: 1px solid rgb(64, 148, 195);
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.OrganizationSelectionContainer {
  width: 50%;
  margin-top: 15px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.OrganizationSelectionDescription {
  margin-top: 30px;
  font-size: 18px;
  width: 50%;
  color: grey;
  text-align: justify;
  @media (max-width: 1000px) {
    text-align: left;
  }
}

.OrganizationName {
  display: flex;

  justify-content: space-between;
  width: 100%;
  height: 50px;
  align-items: center;
  padding: 10px;
  min-width: 225px;
  color: rgb(64, 148, 195);
  cursor: pointer;
  svg {
    color: rgb(64, 148, 195) !important;
  }
}

.OrganizationName:hover {
  background-color: lightgray;
}

.OrganizationTagContainer {
  display: flex;
  flex-wrap: wrap;
}

.OrganizationTag {
  border: 1px solid rgba(0, 0, 0, 0.54);
  border-radius: 5px;
  width: 60px;
  height: 30px;
  font-size: 10px;
  margin: 2px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .OrganizationTagText {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    width: 50px;
  }
}

.OrganizationTag:hover {
  background-color: rgb(64, 148, 195);
  cursor: pointer;
  color: white;
}

.OrganizationTag:hover svg {
  color: white !important;
}

.OrganizationManagementSection {
  width: 100%;
  padding: 20px;
  margin-top: 10px;
  svg {
    color: rgb(64, 148, 195) !important;
  }

  .OrganizationManagementSectionHeader {
    display: flex;
    margin-left: 20px;
    justify-content: space-between;
    align-items: center;
  }
}

.OrganizationInnerManagementSection {
  border: 0.5px solid grey;
  padding: 10px;
  margin-top: 10px;
}

.OrganizationHeader {
  font-size: 34px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid black;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.PanelHeader {
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid black;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.OrganizationHeader * {
  @media (max-width: 1300px) {
    font-size: 24px;
  }

  @media (max-width: 850px) {
    font-size: 18px;
  }
}

.OrganizationManagementHeaderText {
  margin-right: auto;
  user-select: none;
}

.OrganizationManagementHeaderSelectedOrg {
  color: rgb(64, 148, 195);
  cursor: pointer;
}

.OrganizationManagementHeaderBackButton {
  width: 100%;
  @media (max-width: 600px) {
    padding: 0;
  }
  height: 100%;
  cursor: pointer;
}

.OrganizationManagementHeaderBackButtonContainer {
  margin-right: auto;
  position: relative;
  z-index: 1;
  height: 100%;
  width: 75px;
  padding-left: 15px;
}

.OrganizationTagEditorContainer {
  margin-top: 5px;
  min-height: 75px;
  max-width: 280px;
  border: 1px solid rgb(64, 148, 195);
  padding: 8px;
}

.OrganizationTagButtonContainer {
  display: flex;
  justify-content: right;
  margin-right: 10px;
  margin-top: 10px;
  height: 30px;
  button {
    text-transform: none !important;
    border-radius: 0px;
  }
}

.OrganizationSaveButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
}

.OrganizationDeleteButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: $danger-default;
  cursor: pointer;
}

.OrganizationHoldingStatementSelection {
  width: 280px;
  @media (max-width: 600px) {
    width: 200px;
  }
}

.OrganizationHoldingStatement {
  margin-top: 20px;
}

.OrganizationHoldingStatementEditContainer {
  border: 1px solid rgb(64, 148, 195);
  margin-top: 20px;
  margin-left: 0px;
  padding: 10px;
}

.OrganizationManagementDisclaimer {
  color: grey;
  font-size: 20px;
  width: 80%;
  margin-top: 20px;

  @media (max-width: 850px) {
    font-size: 14px;
  }
}

.OrganizationManagementSelectionSection {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 30px;
  width: 100%;

  @media (max-width: 1000px) {
    grid-template-columns: 100%;
  }
}

.OrganizationManagementSelectionItem {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: flex-start;
  padding: 0 2rem 0;
  align-items: center;
  cursor: pointer;
  font-size: 22px;
}

.OrganizationManagementSelectionItem:hover {
  box-shadow: inset 0 0 0 0.1em rgb(64, 148, 195);
}

.IssueForDelete {
  padding: 0.5rem;
  margin-left: 2rem;
  margin-top: 0.5rem;
  border-top: 1px solid #000000;
}

.IssueSectionForDelete {
  padding: 0.5rem;
  margin-left: 2rem;
  margin-top: 0.5rem;
  border-top: 0.25px solid #bbbbbb;
}

.majorSectionDiv {
  padding: 10px;
  border-bottom: 1px solid #000000;
  //box-shadow: inset 0 0 0 0.1em rgb(64, 148, 195);
  margin-bottom: 24px;
}
.minorSectionDiv {
  padding: 5px;
  margin-left: 50px;
  //padding-right: 15px;
  //border: 1px solid #000000;
  //box-shadow: inset 0 0 0 0.1em rgb(64, 148, 195);
  //margin-bottom: 4px;
}

.iconWrapper svg {
  @include themeMod(color, primaryIconColor);
  cursor: pointer;
}
.iconWrapper:hover svg {
  color: darken($primary-blue, 20%);
}

.queryControlItem {
  width: 100%;
  height: 4rem;
}

.severity {
  position: relative;
  padding-left: 20px;

  > span {
    display: inline-block;
    vertical-align: middle;
  }

  > .severityColor {
    position: absolute;
    left: 0px;
    top: 4px;
    width: 16px;
    height: 16px;
    border-color: rgba(0, 0, 0, 0.1);
    border-style: solid;
    border-width: 1px;
  }
}

.trend {
  font-weight: 500;
  font-size: 0.7rem;
  overflow: hidden;
  white-space: nowrap;
}

.tagBubble {
  border-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  border-width: 1px;
  width: fit-content;
  height: fit-content;
  padding: 0.4em 0.4em;
  font-size: 0.7rem;
  font-weight: 400;
  border-radius: 0.7rem;
  background-color: lightgray;
  margin: 0.2rem;
}
.tblContHeight {
  height: auto;
  max-height: auto;
}

.iac_snt_msgSubject textarea {
  padding-top: 1rem;
}

.backgroundWhite {
  background-color: white;
}
.orgSelectorGrey {
  color: $labelGrey;
}
