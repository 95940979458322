.container {
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.container.mobile.focusedMobile {
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.container.mobile.focusedMobile > :global.ql-toolbar {
  visibility: visible !important;
}

.container.mobile.focusedMobile {
  border-style: solid !important;
}

.container.hideControls > :global.ql-toolbar {
  visibility: hidden;
}

.container.hideControls {
  border-style: none;
}

.container:hover.hideControls > :global.ql-toolbar,
.container.mobile.focusedMobile > :global.ql-toolbar {
  visibility: visible;
}

.container:hover.hideControls,
.container.mobile.focusedMobile {
  border-style: solid;
}

.container:hover.hideControls.hideControlsHover > :global.ql-toolbar {
  visibility: hidden;
}

.container:hover.hideControls.hideControlsHover {
  border-style: none;
}

.container.mobile:hover > :global.ql-toolbar {
  visibility: hidden;
}

.container.mobile:hover {
  border-style: none;
}

.toolbar select {
  border: 1px solid #ccc;
  padding: 4px;
  font-size: 14px;
  margin-right: 10px;
  border-radius: 4px;
}

.toolbar button {
  border: none;
  background: none;
  font-size: 16px;
  margin-right: 8px;
  cursor: pointer;
  padding: 5px;
}

.toolbar button.active {
  background-color: blue;
  border-radius: 3px;
  background-color: blue;
}

.toolbar button:hover {
  background-color: #f0f0f0;
}

.editor {
  flex-grow: 1;
  border: 1px solid #ddd;
  min-height: 150px;
  outline: none;
  padding: 10px;
  cursor: text;
  border: none;
}

.toolbar button > b,
.toolbar button > i,
.toolbar button > u,
.toolbar button > s {
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

.ProseMirror:focus {
  outline: none;
}
