.root {
  position: relative;
  word-break: break-word;
}

/*.root::before {
    content: "...";
    position: absolute;
    bottom: 0;
    right: 25px;
}*/

.root.hidden::before {
  display: none;
}

.root .ellipsisBlocker {
  content: '';
  position: absolute;
  right: 0; /* note: not using bottom */
  width: 1rem;
  height: 1rem;
  background: inherit;
}
