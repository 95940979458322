@import '../ThemeProvider/mixins.scss';
@import '../ThemeProvider/variables.scss';

.log {
  h5 {
    font-size: $fontSizeLg;
    font-weight: bold;
    padding-bottom: 16px;
    text-transform: uppercase;
  }

  padding-bottom: 16px;
}

.noActivitySummaryBody {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logItem {
  display: flex;
  margin-bottom: 10px;
  width: 100%;
  border: 0.5px solid black;
  border-radius: 3px;
  padding: 6px;
  min-height: 55px;
  .iconSection {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;

    > * {
      width: 28px;
      height: 28px;
    }
  }

  .contentSection {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .leftSection {
      display: flex;
      flex-direction: column;
      width: 60%;
      .headerContainer {
        .headerText {
          font-weight: bold;
          font-size: $fontSizeMd;
          font-weight: bold;
          margin-bottom: 0px;
        }

        .categoryLabel {
          font-size: 11px;
        }
      }
    }
    .rightSection {
      display: flex;
      flex-direction: column;
      .dateContainer {
        display: flex;
        flex-direction: column;
        text-align: right;
      }
    }
  }

  .subHeaderSection {
    margin-top: 0px;
    white-space: inherit;
  }

  .content {
    padding-left: 16px;
  }

  .extraInfo {
    margin-top: 8px;
    font-size: $fontSizeXs;
    @include themeMod(color, fontColor);
    text-align: left;
  }
}

.subHeaderSection {
  composes: single-line-text from global;
}

.logItem.clickable {
  cursor: pointer;

  &:hover {
    @include themeMod(border-color, activeColor);
    @include themeMod(background-color, activeBackgroundColor);
  }
}

.loadingContainer {
  position: relative;
  min-height: 200px;
  max-height: 700px;
}
