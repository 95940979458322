@import 'mixins.scss';

@mixin listItem($background: false, $includeRules: false) {
  @if $includeRules {
    .MuiListItem-root.Mui-selected,
    .Ico-MuiListItem-root.MuiListItem-root:hover,
    .Ico-Item-root:hover,
    .Ico-Item-root.Ico-selected,
    .MuiListItem-root.Mui-selected:hover,
    .MuiTableRow-root.Mui-selected,
    .MuiTableRow-root.Mui-selected:hover,
    .react-lazylog .ReactVirtualized__Grid__innerScrollContainer > div:hover {
      @include listItemSelected(false);
    }

    .MuiListItem-root.Mui-selected .svg-inline--fa,
    .MuiListItem-root.Mui-selected:hover .svg-inline--fa,
    Ico-Item-root.Ico-selected .svg-inline--fa,
    Ico-Item-root.Ico-selected:hover .svg-inline--fa,
    .Ico-MuiListItem-root.MuiListItem-root:hover .svg-inline--fa {
      @include theme(color, activeColor);
    }
  } @else {
    @include listItemSelected(false);
  }
}

@mixin listItemSelected($module: false) {
  @if $module {
    @include themeMod(background-color, activeBackgroundColor);
    @include themeMod(outline-color, activeColor);
  } @else {
    @include theme(background-color, activeBackgroundColor);
    @include theme(outline-color, activeColor);
  }

  outline-width: 2px;
  outline-style: solid;
}
