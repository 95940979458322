@import '../ThemeProvider/ColorDefinitions.module.scss';
@import '../ThemeProvider/mixins.scss';

.root {
  position: relative;
  height: 100%;
}

.rootHeader {
  padding-top: 2px;
  padding-bottom: 2px;
  text-overflow: ellipsis;
  word-wrap: normal;
  white-space: nowrap;
  text-align: center;
  color: $primary-blue;
}

.usersContainer {
  width: 100%;
}

.usersTextArea {
  width: 100%;
}

.searchInput {
  composes: mb-2 from global;
  height: 38px;
}

.mobile {
  display: flex;
  flex-direction: column;

  .transferList {
    flex-grow: 1;
    height: auto;
    max-height: none;
  }

  .usersContainer {
    padding: 0px !important;
  }
}

.noPlaybooksMsg {
  composes: pt-5 from global;
}

.iconWrapper svg {
  @include themeMod(color, primaryIconColor);
  cursor: pointer;
  &:hover {
    color: darken($primary-blue, 20%);
  }
}
.colorSecondaryIcon.colorSecondaryIcon svg {
  color: $midGray;
  &:hover {
    color: darken($midGray, 20%);
  }
}
.searchBox {
  display: flex;
  align-items: flex-end;
  padding-left: 4px;
  padding-bottom: 4px;
}
.label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
}
.pillBox {
  border-radius: 5px;
  font-size: 0.6rem;
  text-transform: lowercase;
  overflow: hidden;
  white-space: nowrap;
  width: 140px;
  word-break: normal;
  text-overflow: ellipsis;
  margin: 2px;
  cursor: pointer;

  .pillContent {
    width: 130px;
    overflow: hidden;
    white-space: nowrap;
    word-break: normal;
    text-overflow: ellipsis;
  }
}

.selectItem {
  display: block;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis !important;
  word-break: normal;
  &:hover {
    color: darken($primary-blue, 20%);
  }
}
