@import '../ThemeProvider/mixins.scss';

.pdf {
  color: #d22b2b;
}

.contentHolder {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  border-style: solid;
  @include themeMod(border-color, borderColor);
}
