.root > * {
  list-style-type: none;
  margin-top: 2px;
}

.root {
  padding: 0;
  padding-top: 10px;
}

.root > *:last-child {
  margin-bottom: 2px;
}
