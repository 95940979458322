@import '../ThemeProvider/mixins.scss';

.pane {
  padding-bottom: 0;
  position: relative;
  width: 100%;
}

.title {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  word-break: 'break-word';
  font-weight: 'bold';
  padding-right: 15px;
  font-weight: 500;
}

.description {
  font-size: 0.9rem;
  display: 'block';
}

.level {
  font-size: 0.7rem;
  text-align: center;
  font-weight: normal;
  overflow: hidden;
  white-space: nowrap;
  border-bottom-style: solid;
  border-bottom-width: 4px;
  max-width: 100px;
  min-width: 100%;
}

.trend {
  font-weight: 400;
  font-size: 0.7rem;
  overflow: hidden;
  white-space: nowrap;
}

dateTime {
  font-size: 0.7rem;
  font-weight: 500;
}

.dateSegment {
  background-color: lighten($color: $primary-blue, $amount: 42%);
  padding: 4px 6px;
  font-size: 0.7rem;
}

.lastActivity {
  float: left;
}

.typeDescr {
  font-weight: bold;
  padding-right: 6px;
}

.responsible {
  > label {
    margin-left: 0px;
    font-weight: bold;
  }

  > span {
    word-break: break-all;
  }
}

.label {
  font-size: 0.7rem;
  color: $labelGrey;
}

.orgName {
  font-size: 0.7rem;
  color: $labelGrey;
}

.flexRow {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 100%;
  height: 100%;
  flex-basis: 1;
}
