.inviteType {
  width: 140px;
  margin-right: 5px;
}

.inputContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: start;
  align-items: start;
}

@media (max-width: 1040px) {
  .inviteType {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 10px;
    padding-right: 75px;
  }

  .inputContainer {
    display: block;
  }
}

.orgUgisMembers {
  padding-top: 0px;
}
