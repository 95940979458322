@import '../ThemeProvider/mixins.scss';

.root {
}

.searchIcon {
  color: #e0e0e0;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 5px;
}

.participantList {
  max-height: 500px;
}

.menuTrigger,
.settingsTrigger {
  cursor: pointer;
  position: relative;
  height: 24px;
  width: 24px;
  display: inline-block;

  svg {
    font-size: 24px;
    position: absolute;

    @include themeMod(color, menuTiggerColor);
    @include transition(opacity 0.6s);

    &:hover {
      opacity: 0.6;
    }

    &.menuOpened {
      color: white;
    }
  }
}

.settingsTrigger {
  width: 16px;
  margin-right: 7px;

  svg {
    font-size: 18px;
  }
}

.progress,
.progress:global(.MuiBackdrop-root) {
  position: absolute;
  background: transparent;
}

.autocomplete {
  @include themeMod(border-color, activeColor);
  border-style: solid;
  border-width: 2px;
  padding: 4px;

  :global(.MuiInput-underline:after) {
    border-color: $lightGray;
  }

  :global(.MuiAutocomplete-endAdornment .MuiSvgIcon-root) {
    @include themeMod(color, activeColor);
    /*font-size: 30px;
        position: absolute;
        top: -10px;
        right: 0px;*/
  }
}

.autocompleteOpened {
  .chatSearchHeader {
    background: transparent;
  }
}
