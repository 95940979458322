@import './ThemeProvider/ColorDefinitions.module.scss';

.root.tabNoMinWidth > :global.MuiTabs-scroller .MuiTab-root {
  min-width: unset;
}

.root.iconLabelNoMinHeight > :global.MuiTabs-scroller .MuiTab-root {
  min-height: unset;
}

.root > :global.MuiTabs-scroller {
  &:global > div .MuiTab-textColorPrimary.Mui-selected,
  &:global > div .MuiTab-textColorPrimary.Mui-selected svg {
    color: $primary-blue;
  }

  &:global > div .MuiTab-textColorPrimary svg {
    color: inherit;
  }

  &:global > .MuiTabs-indicator {
    background-color: $primary-blue;
  }
}
