.severity {
  position: relative;
  padding-left: 20px;
  font-size: 12px;

  > span {
    display: inline-block;
    vertical-align: middle;
  }

  > .severityColor {
    position: absolute;
    left: 5px;
    top: 4px;
    width: 12px;
    height: 12px;
    border-color: rgba(0, 0, 0, 0.1);
    border-style: solid;
    border-width: 1px;
  }
}
