div#iccContacts {
  border-width: 0;
  overflow: visible;
  overflow-x: hidden;
  padding: 1em;
}
.ICOresplist,
div#iccContacts div {
  margin: 0.5em 0;
  border-radius: 0.3125em;
  background-clip: padding-box;
  /*background-color: #f6f6f6;*/
  border-color: #bbb;
  color: #333;
  text-shadow: 0 1px 0 #f3f3f3;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
div[data-role='collapsible'],
div#iccContacts div div {
  margin: -1px 0 1rem;
  background-color: #f6f6f6;
  border-color: #bbb;
  border-style: solid;
  border-width: 1px;
  color: #333;
  text-shadow: 0 1px 0 #f3f3f3;
  background-clip: padding-box;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding: 0;
}
div[data-role='collapsible']:first-child,
div#iccContacts div div:first-child {
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
  margin-top: 0;
}
div[data-role='collapsible'] h3,
div#iccContacts div div h3 {
  text-decoration: none !important;
  user-select: none;
  cursor: pointer;
  border-radius: inherit;
  display: grid;
  margin: 0;
  position: relative;
  padding: 0.75rem 0 0.75rem 1rem;
}
/*div[data-role='collapsible'] h3:before {
    position: absolute;
    font-family: 'FontAwesome' !important;
    content: '\f055';
    color: rgba(0,0,0,0.3);
    left: .25rem;
    padding-top: .25rem;
    display: grid;
    place-self: center;
    justify-self: center;
}*/
div[data-role='collapsible'] ul[data-role='listview'] li a,
div#iccContacts div div ul li a {
  text-decoration: none !important;
  user-select: none;
  cursor: pointer;
  font-weight: 700;
  text-shadow: 0 1px 0 #f3f3f3;
  color: #333;
  border-color: #ddd;
  border-style: solid;
  border-width: 1px;
  background-color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  position: relative;
  display: block;
  margin: 0;
  border-style: solid;
  border-width: 1px 0 0;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  border-bottom-width: 0;
  border-top-width: 0;
  padding: 0.5rem;
}
div[data-role='collapsible']
  ul[data-role='listview']
  li
  a
  div
  div[style='float:right;'],
div#iccContacts div div ul li a div div:last-child {
  padding: 0.5rem 1.25rem 0 0;
  float: right;
  background: white !important;
  border: 0;
  color: var(--icc-blue);
}
div[data-role='collapsible']
  ul[data-role='listview']
  li
  a
  div
  div[style='float:left;'],
div#iccContacts div div ul li a div div:first-child {
  padding: 0.5rem 0 0 0.5rem;
  float: left;
  background: white !important;
  border: 0;
}
/*div[data-role='collapsible'] ul[data-role='listview'] li a div div[style="float:right;"]:after {
    position: absolute;
    font-family:'FontAwesome' !important;
    content: '\f138';
    color: rgba(0,0,0,0.3);
    height: 2rem;
    width: 2rem;
    right: -.5rem;
    top: auto;
}*/
ul[data-role='listview'],
div#iccContacts div div ul {
  list-style: none;
  list-style-position: outside;
  padding: 0;
  margin: 0;
}
div#iccContacts div div ul li a div {
  border: 0 none;
}
.ICOresplist span {
  cursor: pointer;
}
