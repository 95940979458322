.root {
  padding: 5px;
  position: relative;
}

.artifactTitle {
  font-weight: bold;
}

.root.noBorder {
  @extend .root;
  border-width: 0;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
}

.actions {
  top: -10px;
  right: -10px;
  position: absolute;
}

.bodyCanEdit {
  padding-right: 25px;
}
