.root {
  white-space: nowrap;

  &:before,
  &:after {
    content: '(';
    font-size: 24px;
    padding-right: 1px;
    line-height: 0px;
  }

  &:before {
    margin-right: -3px;
  }

  &:after {
    content: ')';
    padding-left: 1px;
  }
}
