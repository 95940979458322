@import '../ThemeProvider/mixins.scss';
@import '../ThemeProvider/_listItem.scss';
:root {
  --icc-red: #bc202e;
  --icc-orange: #f58220;
  --icc-yellow: #f9c93a;
  --icc-green: #94b042;
  --icc-blue: #3e91c5;
  --icc-blue-hover: #285d7e;
  --icc-blue-hover-light: #d7e8f3;
  --ms-purple: #5c2d91;
  --icc-brown: #4e3323;
  --icc-white: #fff;
  --icc-gray: #676767;
  --icc-darkGray: #4b4b4b;
  --icc-lightGray: #d7d7d7;
  --icc-black: #393938;
  --icc-list-secondary: #f7f7f7;
  --icc-list-secondary-hover: #e6e6e6;
  --versionNumber: #dedede;
  --ms-purpleLight-hover: #b4a0ff;
  --icc-blue-disabled: ##b1d2e7;
}

.planImg {
  height: 1.5rem;
  width: 1.5rem;
}

.actionImg {
  height: 1.8rem;
  width: 1.8rem;
  margin-top: 5px;
  vertical-align: middle;
  align-self: center;
}

.rdsBlue {
  color: var(--icc-blue) !important;
}

.rdsRed {
  color: var(--icc-red) !important;
}

.rdsLightGray {
  color: var(--icc-lightGray) !important;
}

.rdsTreeControl {
  color: var(--icc-blue) !important;
}

.rdsLockedColor {
  color: var(--icc-gray) !important;
  background-color: #ffff00;
  border-color: #ffff00;
  border-style: solid;
  border-width: 2px;
}

.rdsGray {
  color: var(--icc-gray) !important;
}

.rdsYellow {
  color: var(--icc-yellow) !important;
}

.rdsGreen {
  color: var(--icc-green) !important;
}

.alignCenter {
  display: block;
  text-align: center;
  margin: 0 auto;
}

.icoAddItem:nth-child(even) {
  // this makes alternating lines different color
  background-color: var(--icc-list-secondary) !important;
}

.icoAddedItemSelected {
  border-color: $primary-blue;
  border-style: solid;
  border-width: 1px;
}

.addPlanSpec {
  margin-left: 0.5rem;
  width: 80%;
}

.pad20 {
  //padding-top: 20px;
  padding-bottom: 10px;
  // margin-top: 80px;
  // margin-bottom: 80px;
}

.icoTreeSubOrgBorder {
  //border-color: var(--icc-lightGray);
  //border-style: solid;
  //border-width: 1px;
  border: 1px solid var(--icc-lightGray);
  padding: 0.375rem;
}
.icoTreeSubOrgBorder span,
.icoTreeSubOrgBorder div {
  padding: 0.25rem;
}
/* JDB Added 2022-09-07 to give Include Playbooks 50/50 layout...alter widths by changing 50 to whatever needed for necesssary distribution of columns */
.panel50:nth-child(1) {
  width: 50% !important;
}
.panel50:nth-child(2) {
  width: 50% !important;
  margin-top: 1.5rem;
}
.paddingIncludePlaybook {
  padding: 1rem;
}
