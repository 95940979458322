:root {
  --icc-red: #bc202e;
  --icc-orange: #f58220;
  --icc-yellow: #f9c93a;
  --icc-green: #94b042;
  --icc-blue: #3e91c5;
  --icc-blue-hover: #285d7e;
  --ms-purple: #5c2d91;
  --icc-brown: #4e3323;
  --icc-white: #fff;
  --icc-gray: #676767;
  --icc-darkGray: #4b4b4b;
  --icc-black: #393938;
  --icc-list-secondary: #eaeaea;
  --versionNumber: #dedede;
  --ms-purpleLight-hover: #b4a0ff;
  --icc-blue-disabled: ##b1d2e7;
}

.pbListContainer {
  composes: p-2 h-100 from global;
}

.mobile.pbListContainer {
  padding: 0px;
  padding-top: 40px !important;
  background: white;
}

.pbList {
  composes: overflow-auto h-100 from global;
}

.pbEvDetail {
  composes: p-2 h-100 from global;
}

.clickBox {
  padding: 0.75rem 0.375rem;
  cursor: pointer;
  display: flex;
}

.planImg,
.eventImg {
  height: 1.5rem;
  width: 1.5rem;
}

.planImg img {
  vertical-align: middle;
  display: block;
  margin: 0.5rem auto 0;
  width: 100%;
}

.eventImg img {
  vertical-align: middle;
  display: block;
  width: 100%;
}

.planSpec {
  margin-left: 0.5rem;
  width: 90%;
}

.clickBox a,
.clickBox a:active,
.clickBox a:hover {
  text-decoration: none;
  color: inherit !important;
}

.eventPadding {
  padding: 1em;
}

.fontBold {
  font-weight: bold;
}

.initialLoad {
  margin-top: 5em;
}

/*#eventDetail {
    word-wrap: break-word;
}

#playbookList,
#eventList,
#eventDetails,*/

.eventDetails {
  height: 100%;
  position: absolute;
  top: 5em;
  bottom: 0;
  overflow-y: scroll;
}

.iframeStyleContainer {
  position: relative;
  height: 100%;
  width: 100%;
}

.iframeStyle {
  height: 100%;
  width: 100%;
  z-index: 2;
  position: relative;
}

.loadingBackDrop {
  background: transparent;
  opacity: 1;
}

.btnBack {
  position: relative;
  z-index: 2;
  float: left;
}

.btnIncRpt,
.btnDiagram,
.btnContacts {
  position: relative;
  z-index: 2;
  float: right;
}

.btnDiagram,
.btnContacts {
  margin-right: 0.25rem;
}

.ctrDiagramImg {
  display: block;
  margin: 0 auto;
}

.faIR {
  font-size: 2em;
  color: var(--icc-red) !important;
}

.faDia {
  font-size: 2em;
  color: var(--icc-darkGray) !important;
}

.faCB {
  font-size: 2em;
  color: var(--icc-darkGray) !important;
}

.faBack {
  font-size: 2em;
  color: var(--icc-black) !important;
}

.breakLines {
  word-wrap: break-word;
  word-break: break-all;
}

.eventDetailPadTop {
  margin-top: 2em;
}

.icoItem:nth-child(odd) {
  background-color: var(--icc-list-secondary) !important;
}

pre {
  white-space: pre-wrap;
  word-break: keep-all;
}

.pollSpacing {
  margin-bottom: 0.75rem;
}

.pollSpacing:first-of-type {
  margin-top: 0.25rem;
}

input[type='radio'] {
  border-radius: 100%;
}

input[type='checkbox'],
input[type='radio'] {
  min-width: 20px;
  min-height: 20px;
  vertical-align: sub;
}

label {
  margin-left: 0.33rem;
  font-size: 87.5%;
}

.btnIcc {
  background-color: var(--icc-blue) !important;
  border-color: var(--icc-blue) !important;
  color: var(--icc-white) !important;
}

.btnIcc:hover {
  background-color: var(--icc-blue-hover) !important;
  border-color: var(--icc-blue-hover) !important;
  color: var(--icc-white) !important;
}

/*@media (min-width: 768px) {
    #playbookList {
        left: 0;
    }

    #eventList {
        left: 33.333333%;
    }

    #eventDetails {
        right: 0;
    }
}*/
