@import './ThemeProvider/mixins.scss';
@import './ThemeProvider/variables.scss';
@import './ThemeProvider/ColorDefinitions.module.scss';

.root {
  position: relative;
  background-color: $background-white !important;

  .appName {
    font-size: 24px;
    top: 5px;
    position: relative;
    padding-left: 25px;
  }

  .addSpace {
    flex-grow: 1;
    margin-right: 25px;
  }

  .userContainer {
    position: relative;
    z-index: 3;
  }

  &.mobile .userContainer {
    position: absolute;
    top: 7px;
    right: 7px;
  }

  svg.help {
    @include themeMod(color, primaryIconColor);
  }

  .divider {
    height: 35px;
    width: 1px;
    background-color: lightgray;
  }
}

.toolbar {
  composes: m-0 w-100 d-flex justify-content-start from global;
}

.filterContainer {
  max-width: 400px;
}

.filter {
  composes: ml-5 mr-1 from global;
  flex-grow: 1;
  max-width: 900px;
}

.filterMobile {
  max-width: 200px;
}

.userIconContainer {
  min-width: 65px;
  position: relative;
  display: flex;
  justify-content: right;
}

.hamburger,
.userIcon,
.summaryIcon {
  composes: action important from './ThemeProvider/icons.module.scss';
}

.mobile .userIcon,
.summaryIcon {
  font-size: 24px;
}
.userIcon {
  font-size: 24px;
}

.summaryIcon {
  font-size: 24px;
  composes: mr-3 from global;
}

.hamburger {
  font-size: 24px;
}
.debugIcon {
  color: red !important;
  font-size: 24px;
  @include transition(opacity 0.6s);
  cursor: pointer;
  position: relative;
  z-index: 20;
  &:hover {
    opacity: 0.6;
  }
  composes: mr-3 from global;
}

.mobile .hamburger {
  position: absolute;
  top: 7px;
  left: 7px;
  font-size: 20px;
}

.accountWarning {
  composes: text-warning from global;
  position: absolute;
  right: -3px;
  bottom: 5px;
  font-size: 14px;
  z-index: 25;
}

.appInfo {
  width: 500px;
  padding: 10px;
  margin: auto;
}

.drawer > :global(.MuiPaper-root.MuiDrawer-paper),
.drawer:global(.MuiDrawer-root),
.drawer:global(.MuiPaper-root) {
  width: 80%;
  /*max-width: 500px;*/
  max-width: 450px;
}

.mobile.drawer > :global(.MuiPaper-root.MuiDrawer-paper),
.mobile.drawer:global(.MuiDrawer-root) {
  width: 100%;
}

.logHeader {
  display: flex;
  padding: 14px;
  .logHeaderTitle {
    font-size: $fontSizeXl;
    text-transform: uppercase;
    display: flex;
  }

  .logHeaderSort * {
    font-size: $fontSizeSm;
    text-transform: none;
  }
}
