.myDateContainer {
  padding-bottom: 0px !important;
  top: 0px;
  align-content: end;
  align-self: flex-end;
}

.root.mobile .myDateContainer > :global(.MuiFormControl-root) {
  margin-top: 10px;
}

root.mobile .myDateContainer > :global(.MuiFormControl-root:first-child) {
  margin-top: 0px;
}

.btn {
  margin: 0 auto;
  display: block;
  min-width: 5rem;
}

.btnJoin {
  margin: 0px 10px;
  width: auto;
  padding: 0.25rem 0.5rem;
}
.groupStyle {
  width: auto;
}
.footerStyle {
  width: auto;
  display: flex;
}
.footerItem {
  display: inline-flex;
  flex: auto;
}

.iqContainer {
  /*max-height: 100px;*/
  top: 0px;
  align-content: end;
  align-self: flex-end;
  text-align: right;
  width: auto;
}

.countrySelect.readOnly > :global.MuiFormControl-root .Mui-disabled {
  color: inherit;
  cursor: text;
  width: 100%;
}

.countrySelect.readOnly
  > :global.MuiFormControl-root
  .MuiInputBase-root.Mui-disabled {
  cursor: text;
  width: 100%;
}

.phoneInput.readOnly {
  color: transparent;
}

.phoneLink {
  bottom: 4px;
  position: absolute;
  z-index: 2;
}

.allDay,
.msTeams {
  vertical-align: bottom;
}

.repeatType {
  width: 100%;
}
.dateField {
  width: 50%;
  padding-right: 0.5rem !important;
}
.timeField {
  width: 25%;
  color: white !important;
}
@media screen and (max-width: 767px) {
  .timeField {
    width: 49%;
  }
}
@media screen and (min-width: 768px) and (max-width: 959px) {
  .dateField {
    width: 50% !important;
  }
  .timeField {
    width: 24.5%;
  }
}
@media screen and (min-width: 960px) and (max-width: 1199px) {
  .dateField {
    width: 100%;
  }
  .timeField {
    width: 49.25%;
  }
}

.firstTimeField {
  padding-right: 0.5rem !important;
}

.dateField.startDate:global(.MuiFormControl-fullWidth) {
  padding-right: 0px !important;
}
.mtgIcon,
.videoIcon,
.clockIcon,
.peopleIcon,
.copyIcon,
.emailIcon,
.iCalIcon {
  width: 1.5rem;
  height: 1.25rem;
  color: lightgray !important;
}

.videoIcon,
.copyIcon,
.peopleIcon,
.mtgIcon,
.emailIcon,
.iCalIcon {
  margin-right: 1rem;
}

.clockIcon {
  margin-top: 0.125rem;
  margin-right: 0.5rem;
  vertical-align: top;
}

.mtgLink,
.peopleList,
.titleLink,
.descLink {
  margin-bottom: 1rem;
  width: calc(100% - 3rem) !important;
}

.copyIcon,
.iCalIcon {
  color: var(--icc-blue) !important;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.floatEnd {
  float: right !important;
  display: flex;
}

.tbWeekdaysButton {
  border-radius: 100% !important;
  padding: 0.5rem;
  height: 2rem;
  width: 2rem;
  margin-right: 0.5rem;
  border: 1px solid var(--icc-blue) !important;
  color: var(--icc-blue);
}

.tbWeekdaysButton:hover {
  background: var(--icc-blue);
  color: white;
}

button.tbWeekdaysButton[aria-pressed='true'] {
  background: var(--icc-blue);
  color: white;
}

.lblRecur {
  display: block;
  margin-top: 0.25rem;
  margin-right: 0.5rem;
}
