.textArea {
  display: block;
  white-space: pre-wrap;
  word-break: break-word;
  border-style: none;
  width: 100%;
  overflow: visible !important;
  resize: none;
  padding-left: 0px;
  padding-bottom: 0px;
}

.resize .textArea {
  resize: both;
}

.borderFull .textArea {
  border-style: solid;
}

.root.fullScreen .textArea {
  padding-left: 2px;
}

.formControl {
  width: 100%;
}

.formControl:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: '\00a0';
  position: absolute;
  /* transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
  pointer-events: none;
}

.borderBottom .formControl:before {
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.borderBottom .formControl {
  overflow-x: hidden;
}

.borderBottom .formControl label {
  margin-bottom: 0px;
}

.formControl:hover .textArea,
.textArea:focus,
.textArea:hover {
  border-style: solid;
}

.borderBottom .formControl:hover .textArea.disabled,
.borderBottom .textArea.disabled:focus,
.borderBottom .textArea.disabled:hover {
  border-style: none;
}
