.textViewer {
  overflow: auto;
}

.textViewer.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.unsupported {
  width: 250px;
  height: 110px;
}

.pdfViewer {
  padding: 70px;
  padding-left: 0px;
  padding-right: 0px;
}
