@import '../ThemeProvider/mixins.scss';

.viewport {
  overflow: auto;
  position: relative;
  height: 100%;
  padding: 5px;
}

.viewportSearch {
  overflow: auto;
  position: relative;
  height: 100%;
  padding: 5px;
}

.list {
  display: block;
  padding-inline-start: 0;
  padding-left: 0;
}
.header {
  @include themeMod(background, headerSecondaryColor);
  color: white;
  padding: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: baseline;
  align-items: baseline;
  z-index: 30;
  position: relative;

  .headerInner {
    display: -webkit-flex;
    display: flex;
  }

  .name {
    font-weight: bold;
    padding-right: 5px;
    flex: 1;
  }

  .lockIcon {
  }

  &.withClick {
    cursor: pointer;
  }
}

.searchIconContainer,
.searchInput {
  border-radius: 0px;
}
