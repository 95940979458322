.content {
  width: auto;
}

.contentInner {
  margin: auto;
  padding: 20px;
  padding-top: 0px;
  padding-bottom: 10px;
}

.section {
  composes: pb-2 from global;
}

.loader {
  position: relative !important;
  padding: 40px;
  padding-top: 20px;
}
