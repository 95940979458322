.issueLevelBorder {
  border-style: none;
  /*border-left-color: lightgray;*/
  border-top-style: solid;
  border-top-width: 10px;
  padding: 0px;
  height: 100%;
}

.disabled,
.workspaceReadonly :global(.MuiPaper-root),
.issueReadonly :global(.MuiPaper-root) {
  opacity: 0.8;
}
