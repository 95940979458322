.header {
  display: flex;
  justify-content: left;
  align-items: center;
  padding-bottom: 15px;
}

.inputAndSubmit {
  display: flex;
  align-items: center;
}
