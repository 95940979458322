.user {
  font-weight: bold;
  padding-right: 160px;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: normal;
  overflow: hidden;
  font-size: 12px;
}

.createdDate {
  position: absolute;
  top: 0;
  right: 0;
  color: gray;
  font-size: 12px;
}

.header {
  position: relative;
  margin-bottom: 7px;
  font-size: 16px;
  /*font-weight: bold;*/
}

.line {
  border: 1px solid #949494;
}

.btn {
  margin-right: 10px;
}

.btnSave {
  margin-right: 0px;
}

.btn_hidden {
  margin-right: 10px;
  visibility: hidden;
}

.disabled {
  pointer-events: none;
}
