/*Always use the root to increase specificity of child components*/
.root .chatMessageEditor {
  border-style: none;
  border-top-style: solid;
  border-color: lightgray;
  width: 100%;
}

.root {
  position: relative;
  height: 100%;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
}

.root.border {
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: lightgray;
}

.header {
  width: 100%;
}

.body {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-flex: 1;
  flex: 1;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  overflow: auto;
}

.content {
  position: relative;
  width: 100%;
  height: 100%;
}

.footer {
  width: 100%;
}

.root.border .footer {
  border-style: none;
  border-top-style: solid;
  border-color: lightgray;
  border-width: 1px;
}

.root.fullScreen {
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.root.fullScreen > .body > .content > :global(*) {
  height: 100% !important;
}

.root.fullScreen > .header {
  visibility: visible !important;
}

.root.fullScreen {
  border-style: solid !important;
}

.root.hideHeaderOnInactive > .header {
  visibility: hidden;
}

.root.hideBorderOnInactive {
  border-style: none;
}

.root.hideHeaderOnInactive:hover .header,
.root.hasFocus.hideHeaderOnInactive > .header,
.root.fullScreen > .header {
  visibility: visible;
}

.root.hideBorderOnInactive:hover,
.root.hasFocus.hideBorderOnInactive,
.root.fullScreen {
  border-style: solid;
}
