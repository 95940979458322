@import '../ThemeProvider/ColorDefinitions.module.scss';

.root {
  border-style: solid;
  border-width: 1px;
  /*border-color: $primary-blue;*/
  border-color: lightgray;
}

.root.active {
  border-radius: 0;
  border-left-width: 10px;
  border-left-color: $primary-blue;
}

.root :global(.MuiCardActions-root) {
  color: $primary-blue;

  :global(.MuiIconButton-label) > svg {
    color: $action-icon-color;
  }
}
