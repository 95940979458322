.root.border {
  border-style: solid;
  border-width: 1px;
  border-color: gray;
}

.sendBtn {
  padding: 2px;
}

.uploadBtn {
  cursor: pointer;
}

.toolbar {
}
