@import '../ThemeProvider/mixins.scss';
.root {
  > :global.MuiDivider-root {
    background-color: #dbe3ed;
  }

  svg {
    @include themeMod(color, fontColor);
  }

  svg.icon {
    cursor: pointer;
    min-width: 24px;
    max-width: 24px;
  }
  .danger {
    @include themeMod(color, danger);
  }
  .callDateTime {
    color: $primary-blue;
  }

  .summaryItem > ul {
    margin-bottom: 0px;
  }

  .fontOrange {
    color: #f58220;
  }

  .fontGray {
    color: #4b4b4b;
  }

  .fontBlue {
    @include theme(font-color, activeColor);
  }

  .psecondary {
    margin: 0;
    padding: 0;
    font-size: 12px;
    color: #6c757d;
  }

  .divsecondary {
    margin: 0;
    padding: 0;
    font-size: 12px;
    color: #6c757d;
  }

  .advisoryIcon {
    margin-right: 4px;
    margin-left: 4px;
  }

  .advisoryIcon.compact {
    padding: 2px;
    margin: 4px;
  }
}

.iconBox {
  width: 20px;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeButton {
  color: $primary-blue;
  &:hover {
    filter: brightness(0.7);
  }
}

.reverseFlexRow {
  display: flex;
  flex-direction: row-reverse;
}

.close {
  background-color: transparent;
  border: 0;
  padding-bottom: 0px;

  span {
    font-size: 1.5rem;
    font-weight: 700;
  }

  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  color: gray;

  &:hover {
    @include themeMod(color, activeColor);
  }
}

.noHoverColor {
  filter: none !important;
}
