@import '../ThemeProvider/mixins.scss';

.root {
  width: 100%;
  background: white;

  margin-top: 10px;
  height: 100%;
}

.header {
  border-bottom-style: none;
  border-width: 0px;
}

.checkboxContainer {
  display: flex;
  justify-content: center;
  margin-top: -8px;
  align-items: start;
  width: 50px;
}

.taskContentContainer {
  width: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
}

.taskContentTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 14px;
}

.taskContentAssignedTo {
  direction: rtl;
  font-size: 70%;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.taskTimeFrame {
  font-size: 10px;
  .taskTimeFrameText {
    font-size: 10px;
    display: flex;
    align-items: center;
  }

  .taskTimeFrameOverdue {
    color: red;
  }
}

.taskCommentsAndDescription {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 50px;

  .taskComment {
    display: flex;
    align-items: flex-start;
  }

  .taskCommentsCount {
    font-size: 10px;
    margin-top: -10px;
  }
}
