@import '../../ThemeProvider/ColorDefinitions.module.scss';

.clickableSection {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: $lightBlue;
  }
}
