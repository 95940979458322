.root {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border-color: lightgray;
  border-width: 2px;
  border-style: solid;
  display: inline-block;
  vertical-align: middle;
}
