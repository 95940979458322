/*******************************
* In Case of Crisis
* Report Form Styling
* Last Updated: 2016-07-07 11:52
*
* Color Palette
* Orange: var(--icc-blue)
* New Blue: #4E91C6
* New Green: #93B03D
********************************/

:root {
  --icc-red: #bc202e;
  --icc-orange: #f58220;
  --icc-yellow: #f9c93a;
  --icc-green: #94b042;
  --icc-blue: #3e91c5;
  --icc-blue-hover: #285d7e;
  --ms-purple: #5c2d91;
  --icc-brown: #4e3323;
  --icc-white: #fff;
  --icc-gray: #676767;
  --icc-darkGray: #4b4b4b;
  --icc-black: #393938;
  --icc-list-secondary: #eaeaea;
  --versionNumber: #dedede;
  --ms-purpleLight-hover: #b4a0ff;
  --icc-blue-disabled: ##b1d2e7;
  --icc-teal: #008272;
}

.ico_team_report_form_content {
  min-width: 250px;
  background: #ffffff;
  font-family: Helvetica, Arial, sans-serif;
}

/*Remove the !important and deal with margins*/
.ico_team_report_form_content #submitButton,
.ico_team_report_form_content #btnCancel,
.ico_team_report_form_content input[type='submit'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 100%;
  height: 2.5em;
  width: 50%;
  outline: none;
  background: var(--icc-blue);
  color: var(--icc-white);
  border: 1px solid var(--icc-blue);
  border-radius: 2px;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.2s;
  display: block;
  margin: 0 auto;
}

.ico_team_report_form_content #submitButton:active,
.ico_team_report_form_content #btnCancel:active,
.ico_team_report_form_content input[type='submit']:active {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: var(--icc-blue-hover) !important;
  border: 1px solid var(--icc-blue-hover) !important;
  color: var(--icc-white) !important;
}

/*Begining of element styling*/
.ico_team_report_form_content input[type='text'],
.ico_team_report_form_content textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  outline: none;
  width: 98% !important;
  padding: 0.5em 0 0.25em 0.5em;
  margin-bottom: 0.25em;
  font-size: 16px;
  border-style: none;
  border-bottom: 1px solid #9e9e9e;
  box-shadow: none;
  transition: all 0.3s;
}

/* Creates the highlight on select */
.ico_team_report_form_content input[type='text']:focus,
.ico_team_report_form_content textarea:focus {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-bottom: 1px solid var(--icc-blue);
  box-shadow: 0 1px 0 0 var(--icc-blue);
}

.ico_team_report_form_content div {
  margin-bottom: 2em;
}

.ico_team_report_form_content input[type='checkbox'],
.ico_team_report_form_content input[type='radio'] {
  margin-right: 0.5em;
}

/* Select */
.ico_team_report_form_content select,
.ico_team_report_form_content input[type='date'],
.ico_team_report_form_content input[type='datetime-local'],
.ico_team_report_form_content input[type='datetime'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  width: 100%;
  padding: 0.5em 1em 0.5em 1em;
  margin-top: 1em;
  font-size: 12px;
  outline: none;
  border: 1px solid var(--icc-blue);
  border-radius: 5px;
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
}

.ico_team_report_form_content input[type='date'],
.ico_team_report_form_content input[type='datetime-local'],
.ico_team_report_form_content input[type='datetime'] {
  width: 91%;
  min-height: 1em;
  padding: 0.5em 1em 0.5em 1em;
}

/* Label styling */
.ico_team_report_form_content label {
  padding: 0.25em;
  margin: 0.5em 0.5em;
  display: block;
  vertical-align: middle;
  /*box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    color: #FFFFFF;
    background: #4E91C6;
    border-radius: 5px;*/
}
/* Code for styling the label overtop of the fileUpload button */
/*
input[type="file"] {
    display: none;
}
.custom-file-upload {
    margin-left: 1em;
    outline: none;
    background: #93B03D;
    margin-top: 1em;
    border: 1px solid #93B03D;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    color: #FFF;
    font-size: 12px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 10px;
    transition: all .2s;
}
.custom-file-upload:active{
   background: var(--icc-blue);
   border: 1px solid var(--icc-blue);
   color: #FFFFFF;
}
*/
/* Textarea */
.ico_team_report_form_content textarea {
  /*resize: none;*/
  min-height: 150px;
}

/* Necessary to style required fields on submit */
.ico_team_report_form_content .reqInput {
  border: 2px solid red !important;
}

.ico_team_report_form_message,
.ico_team_report_form_content #message {
  font-weight: bold;
  color: red;
}

@media (max-width: 640px) {
  .ico_team_report_form_submitButton,
  .ico_team_report_form_content #btnCancel,
  .ico_team_report_form_content input[type='submit'] {
    width: 100%;
  }
}

@media (min-width: 641px) and (max-width: 767px) {
  .ico_team_report_form_submitButton,
  .ico_team_report_form_content #submitButton,
  .ico_team_report_form_content #btnCancel,
  .ico_team_report_form_content input[type='submit'] {
    width: 70%;
  }
}
