@import '../ThemeProvider/ColorDefinitions.module.scss';
.nameColumn.nameColumn.nameColumn {
  width: 7rem;
  @media (max-width: 768px) {
    width: 6rem;
  }
}
.typeColumn.typeColumn.typeColumn {
  width: 5rem;
  @media (max-width: 768px) {
    width: 4rem;
  }
}
.createdColumn.createdColumn.createdColumn {
  width: 10rem;
  @media (max-width: 768px) {
    width: 5rem;
  }
}
.viewColumn.viewColumn.viewColumn {
  width: 8rem;
  @media (max-width: 768px) {
    width: 3rem;
  }
}
.msTeamsColor.msTeamsColor.msTeamsColor svg {
  color: $msTeamsPurple;
}
