.DataScreen {
  display: flex;
  //flex-wrap: wrap;
  //justify-content: left;
  align-items: left;
  width: 100%;
  height: 100%;
}

.DataControls {
  //display: flex;
  //flex-wrap: wrap;
  //justify-content: left;
  align-items: left;
  width: 20%;
}
.DataReport {
  //display: flex;
  justify-content: left;
  align-items: left;
  width: 80%;
}

.DataReportSection {
  padding: 5px;
  border: 1px solid #000;
  background-color: #dfdfdf;
  margin: 4px;
}

.DataReportDetail {
  padding: 2px !important;
  border: 1px solid #7f7f7f;
  background-color: #efefef;
  margin: 2px !important;
}

.DataReportDetail tr {
  border-bottom: 1px solid #afafaf;
}

.DataReportDetail td {
  //padding: 1px !important;
  padding-left: 2px !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  padding-right: 1px !important;
  border: none;
  margin: 1px !important;
  text-align: left;
  vertical-align: top;
}

.DataReportDetail th {
  //padding: 1px !important;
  padding-left: 2px !important;
  padding-top: 1px !important;
  padding-bottom: 2px !important;
  padding-right: 1px !important;
  border-bottom: 1px solid #7f7f7f;
  margin: 1px !important;
}

.DataReportSingle {
  padding-left: 2px !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  padding-right: 1px !important;
  border: none;
  margin: 1px !important;
}
