.nameInput {
  flex-grow: 1;
  margin-left: 32px;
}

.trash {
  cursor: pointer;

  &.disabled {
    opacity: 0.6;
    cursor: default;
  }
}

.listCon {
  height: calc(50vh - 102px);
  overflow: hidden;
  width: 1500px;
  max-width: 100%;
}

.templateDialog :global(.MuiDialog-paperFullScreen) .listCon {
  height: calc(100vh - 102px);
}

.templateDialog :global(.MuiDialogContent-root) {
  overflow: hidden;
}
