.root {
  .roleSelect,
  .roleName {
    font-size: 14px;
    color: inherit;
  }

  .roleSelect {
    border-style: none;
    cursor: pointer;
    padding-left: 0px;
    margin-left: -4px;
    background: white;
    max-width: 100%;
    text-overflow: ellipsis;

    &:hover,
    &:focus,
    &:active {
      border-style: solid;
    }
  }

  .statusBubble {
    width: 14px !important;
    height: 14px;
    border-radius: 50%;
    left: 0px;
    position: absolute !important;
    z-index: 2;
    border-color: #f7f7f7;
    border-width: 2px;
    border-style: solid;
    display: inline-block;
  }

  td {
    padding-left: 20px !important;
  }
}
