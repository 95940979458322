@import '../ThemeProvider/mixins.scss';
@import '../ThemeProvider/_listItem.scss';
@import '../ThemeProvider/ColorDefinitions.module.scss';
:root {
  --icc-red: #bc202e;
  --icc-orange: #f58220;
  --icc-yellow: #f9c93a;
  --icc-green: #94b042;
  --icc-blue: #3e91c5;
  --icc-blue-hover: #285d7e;
  --icc-blue-hover-light: #d7e8f3;
  --ms-purple: #5c2d91;
  --icc-brown: #4e3323;
  --icc-white: #fff;
  --icc-gray: #676767;
  --icc-darkGray: #4b4b4b;
  --icc-lightGray: #d7d7d7;
  --icc-black: #393938;
  --icc-list-secondary: #f7f7f7;
  --icc-list-secondary-hover: #e6e6e6;
  --versionNumber: #dedede;
  --ms-purpleLight-hover: #b4a0ff;
  --icc-blue-disabled: ##b1d2e7;
}

h2.inboxDetails {
  font-weight: bold;
  text-align: center;
}

.mobile.pbList {
  padding-top: 40px !important;
  background: white;
}

.planImg {
  height: 1.5rem;
  width: 1.5rem;
}

.planImg img {
  vertical-align: middle;
  display: block;
  margin: 0.5rem auto 0;
  width: 100%;
}

.planSpec {
  margin-left: 0.5rem;
  width: 90%;
}

.eventPadding {
  padding: 1em;
}

.font-bold {
  font-weight: bold;
}

.initialLoad {
  margin-top: 5em;
}

/*#eventDetail {
    word-wrap: break-word;
}

#playbookList,
#eventList,
#eventDetails,*/

.eventDetails {
  height: 100%;
  position: absolute;
  top: 5em;
  bottom: 0;
  overflow-y: scroll;
}

.iframeStyleContainer {
  position: relative;
  height: 100%;
  width: 100%;
  .iframeStyle {
    height: 100%;
    width: 100%;
    z-index: 2;
    position: relative;
  }
  .loadingBackDrop {
    background: transparent;
    opacity: 1;
  }
}
.myDropDownContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.myDropDownContainer > select {
  // padding-top: 10px;
  width: 100%;
  max-width: 350px;
  /*padding-bottom: 5px;*/
  font-size: 12px;
  text-align: center;
  vertical-align: middle;
}

.alertCard {
  padding: 0.5rem;
  box-shadow: -2px 2px 5px 5px rgba(234, 234, 234, 0.7);
  border: 1px solid var(--icc-lightGray);
  cursor: pointer;
}

.alertCard:nth-child(odd) {
  background-color: var(--icc-list-secondary);
}

.alertCard:nth-child(odd):hover {
  background-color: var(--icc-list-secondary-hover);
}

.feedItemCard {
  margin-bottom: 1.5rem;
}

.btnIccOrange {
  background-color: var(--icc-orange) !important;
  border-color: var(--icc-orange) !important;
  color: var(--icc-white) !important;
  box-shadow: none !important;
}

.btnIcc {
  background-color: var(--icc-blue) !important;
  border-color: var(--icc-blue) !important;
  color: var(--icc-white) !important;
  box-shadow: none !important;
}

.btnIcc:hover {
  background-color: var(--icc-blue-hover) !important;
  border-color: var(--icc-blue-hover) !important;
  color: var(--icc-white) !important;
  box-shadow: none !important;
}

.rdsBlue {
  color: var(--icc-blue) !important;
}

.editFeed {
  margin-top: -2px;
}

.alignCenter {
  display: block;
  text-align: center;
  margin: 0 auto;
}

.btnCreateInbox {
  color: var(--icc-blue) !important;
  margin-top: 0.5rem;
}

.btnCreateInbox:hover {
  color: var(--icc-blue-hover) !important;
}

.btnEditNotAvailable {
  color: var(--icc-lightGray) !important;
  margin-top: 0.5rem;
}

.feedLogo {
  width: auto;
  max-width: 100px;
  height: auto;
  max-height: 100px;
}

.iconChooserSpacing {
  margin: 0.5rem;
}

.feedItemIcon {
  font-size: 1.5rem !important;
}

.feedItemIconSmall {
  font-size: 1rem !important;
}

.alertDetailCard {
  padding: 1.5rem;
}

.inboxDetails {
  padding: 0.5rem;
}

.inboxDetailsIcon {
  width: 1.125rem !important;
  vertical-align: middle;
}

.alertDetailCard > svg:hover {
  color: white;
}

.btnAlertDetail {
  border: 1px solid var(--icc-blue) !important;
  background-color: white !important;
  color: var(--icc-blue) !important;
  border-radius: 4px;
  margin: 0 0.75rem 0.75rem;
}

.btnAlertDetail:hover {
  background-color: var(--icc-blue-hover-light) !important;
}

.btnAlertDetSpan {
  margin-left: 0.33rem;
}

.issuesIcon {
  width: 1.125rem !important;
}

.alertContentImage {
  width: 100%;
  max-width: 150px;
  float: left;
  vertical-align: middle;
  display: inline-block;
}

.alertDetailsHeader {
  width: 100%;
  padding: 0.5rem;
}

.btnClear {
  padding: 0 !important;
}

.noIssuesFound {
  text-align: center;
  white-space: pre-wrap;
  color: #186faf;
  font-weight: 700;
  width: 100%;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 85vh;
}

.myDateContainer {
  padding-bottom: 0px !important;
  top: 0px;
  align-content: end;
  align-self: flex-end;
}

.dateField {
  width: 50%;
}

.dateField.startDate {
  padding-right: 10px !important;
}

.dateField.startDate:global(.MuiFormControl-fullWidth) {
  padding-right: 0px !important;
}

/* viewports: <767px, 768-991px, 992px-1199px, 1200px-1399px, 1400px+ */

@media (max-width: 339px) {
  .btnAlertDetail {
    margin-right: 0.5rem;
  }
}

@media (max-width: 500px) {
  .btnShowAlerts {
    display: block;
    margin: 0 auto;
  }
  .noIssuesFound {
    min-height: 72vh;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .btnShowAlerts {
    display: block;
    margin: 0 auto;
  }
}

.inboxItem {
  padding: 4px;
  cursor: pointer;
  &:hover {
    color: darken($primary-blue, 20%);
  }
  display: flex;
  justify-content: space-between;
}
.inboxItemName {
  padding-left: 0.3rem;
  width: 100%;
}
.inboxItemContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.inboxSelected {
  background-color: $primary-blue;
  color: white;
  &:hover {
    color: white;
  }
}
.alertSelected {
  box-shadow: inset 5px 0 0 0 $primary-blue;
}
.alertItem {
  border-bottom: solid 1px $text-dark;
  cursor: pointer;
}
.alertTitle {
  font-weight: 500;
  flex-basis: 1;
  font-size: 0.9rem;
}
.alertItemDetails {
  font-weight: 400;
  font-size: 0.8rem;
}
.alertItemDetails img {
  display: none;
}
.alertItemExtra {
  color: $labelGrey;
}
.alertItemExtraSelected {
  color: $lightGray;
}
.updatedSpan {
  flex-basis: 1;
  white-space: nowrap;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.inboxInlineButton {
  cursor: pointer;
  &:hover {
    color: darken($primary-blue, 20%);
    font-weight: 500;
  }
}

.iconWrapper svg {
  @include themeMod(color, primaryIconColor);
  cursor: pointer;
  &:hover {
    color: darken($primary-blue, 20%);
  }
}

.iconWrapperSelected svg {
  @include themeMod(color, white);
  cursor: pointer;
  &:hover {
    color: darken(white, 20%);
  }
}
.iconWrapperDisabled svg {
  @include themeMod(color, primaryIconDisabledColor);
  cursor: pointer;
}

.invertTextColor {
  background-color: white;
  color: black;
}

.reviewedAlert {
  color: $lightGray;
}

.danger {
  background-color: #bc202e !important;
  border-color: #bc202e !important;
  box-shadow: none !important;
  cursor: pointer;
  color: white;
  &:hover {
    background-color: darken(#bc202e, 10%) !important;
  }
}
.disabledButton {
  background-color: lighten(#676767, 10%) !important;
  border-color: lighten(#676767, 10%) !important;
  box-shadow: none;
  cursor: pointer;
  text-transform: none;
  border-radius: none;
}
.btnIcc {
  background-color: var(--icc-blue) !important;
  border-color: var(--icc-blue) !important;
  color: var(--icc-white) !important;
  box-shadow: none !important;
}

.btnIcc:hover {
  background-color: var(--icc-blue-hover) !important;
  border-color: var(--icc-blue-hover) !important;
  color: var(--icc-white) !important;
  box-shadow: none !important;
}
.imgContainer img {
  width: 300px;
  height: 300px;
}

.noFilterApplied.noFilterApplied svg {
  color: $icon-lightBlue;
  cursor: pointer;
  &:hover {
    color: darken($icon-lightBlue, 20%);
  }
}

.formLabel {
  font-weight: 900;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  margin-left: 0px;
}

.showMore {
  font-size: 0.8rem;
  font-style: italic;
  color: $primary-blue;
  cursor: pointer;
}

.inactiveColor {
  color: lighten($labelGrey, 20%);
}
