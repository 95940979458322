@import '../ThemeProvider/mixins.scss';

.root,
.rootactive {
  margin-bottom: 0;
  cursor: pointer;
  border-style: solid;
  border-right-width: 1px;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-radius: 0;
  @include themeMod(border-color, inactiveColor);
  border-left-width: 10px;
  border-left-style: solid;
  position: relative;
  margin-top: 0.75em;
  width: 500px;

  .callDateTime {
    @include themeMod(color, activeColor);
  }

  .active > * {
    color: #000;
    font-weight: bold !important;
  }

  .content {
    padding-bottom: 0;
    position: relative;
  }

  .icon {
    @include themeMod(color, actionIconColor);
  }

  .iconactive {
    @include themeMod(color, activeColor);
  }

  .psecondary {
    margin: 0;
    padding: 0;
  }

  .divsecondary {
    margin: 0;
    padding: 0;
    font-size: 12px;
    color: #6c757d;
  }
}

.rootactive {
  @include themeMod(border-color, activeColor);
  @include themeMod(border-left-color, activeColor);
}

.listItemBorder {
  border-color: var(--icc-blue) !important;
}

.iconColor {
  color: var(--icc-blue) !important;
}
