.root {
  display: flex;
}

.title {
  display: inline;
  /*padding-left: 14px;*/
  white-space: normal;
  flex-grow: 1;
}

.navIcon {
  margin-right: 10px;
  font-size: 20px;
}

.navIconClickArea {
  position: absolute;
  display: inline-block;
  z-index: 1;
  width: 40px;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}

.actions {
  float: right;
  padding-right: 50px;

  &.mobileApp {
  }
}

.hideNav {
  visibility: hidden;
}
