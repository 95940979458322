@import './ThemeProvider/mixins.scss';

.primaryButton {
  all: revert;
  background: $btn-color-primary;
  color: white;
  text-transform: uppercase;
  width: fit-content;
  min-width: 100px;
  height: fit-content;
  letter-spacing: 0.08rem;
  padding: 0.8em 1.6em;
  font-size: 0.7rem;
  font-weight: 400;
  border-radius: 2px;
  margin: 0;
  border: 1px solid transparent;
  box-shadow: none;
  &:hover {
    background-color: darken($color: $btn-color-primary, $amount: 10);
  }
}

.secondaryButton {
  all: revert;
  background: none;
  color: white;
  text-transform: uppercase;
  width: fit-content;
  min-width: 100px;
  height: fit-content;
  letter-spacing: 0.08rem;
  padding: 0.8em 1.6em;
  font-size: 0.7rem;
  font-weight: 400;
  border-radius: 2px;
  margin: 0;
  border: 1px solid $btn-color-secondary;
  box-shadow: none;
  &:hover {
    background-color: darken($color: $btn-color-secondary, $amount: 10);
  }
  &:disabled {
    background-color: darken($color: $btn-color-secondary, $amount: 20);
  }
}
