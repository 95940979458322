@import '../ThemeProvider/ColorDefinitions.module.scss';

.root {
  position: relative;
  padding-left: 0px;
  padding-right: 0px;
  overflow: hidden;

  .scrollContainer,
  .filterContainer {
    padding-right: 0px;
    padding-left: 0px;
  }

  .scrollContainer {
    position: relative;
    overflow: auto;
    height: 100%;
  }

  .filterContainer {
    text-align: right;
    position: relative;
    margin-bottom: 10px;
  }

  .helpScreenContainer {
    position: relative;
    top: 30vh;
    text-align: center;
    white-space: pre-wrap;
    color: $primary-icon-color;
    font-weight: bold;
    width: 100%;
  }

  .issueSummaryItem {
    margin-left: 2px;
  }

  .actions {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-align-items: baseline;
    align-items: baseline;
    width: 100%;
  }

  .actions > * {
    margin-right: 6px;
  }

  .actions > *:last-child {
    margin-right: 0px;
  }

  .issuesNotFoundContainer {
    position: absolute;
    height: 100%;
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    z-index: 1;
  }
}
