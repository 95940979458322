.OrgTag {
  // Inbox Tag color
  &.inbox {
    color: #fff;
    /*background-color: #f50057;*/
    background-color: gray;
    > :global(.MuiChip-deleteIcon) {
      color: rgba(255, 255, 255, 0.7);
      &:hover {
        color: #fff;
      }
    }
    svg {
      color: #fff;
    }
  }
}

.OrgTagOptList {
  > li {
    justify-content: space-between;
  }
}

.AlertInfoIcon {
  margin-left: 4px;
}

.AlertIconCustom {
  background: white;
  border-radius: 4px;
  object-fit: cover;
  padding: 1px;
  &.email {
    height: 1.5rem;
    width: 1.7rem;
    padding: 1px;
    object-position: 0 -3px;
  }
  &.incidentReport {
    height: 1.5rem;
    width: 1.1rem;
    object-position: -3px 0;
  }
  &.nForNoaa {
    height: 1.5rem;
    width: 1.5rem;
  }
  &.news {
    height: 1.5rem;
    width: 1.5rem;
  }
  &.noaa {
    height: 1.5rem;
    width: 1.5rem;
  }
  &.police {
    height: 1.5rem;
    width: 1.5rem;
  }
  &.rss {
    height: 1.5rem;
    width: 1.5rem;
    object-position: 0px 0;
  }
  &.slack {
    height: 1.5rem;
    width: 1.5rem;
  }
  &.tForTx360Noaa {
    height: 1.5rem;
    width: 1.5rem;
  }
  &.teams {
    height: 1.5rem;
    width: 1.5rem;
  }
  &.tx360 {
    height: 1.5rem;
    width: 1.5rem;
  }
  &.weather {
    height: 1.5rem;
    width: 1.5rem;
  }
  &.zapier {
    height: 1.5rem;
    width: 1.5rem;
    object-position: 0px 0;
  }
  &.iccThreat {
    height: 1.5rem;
    width: 1.5rem;
  }
  &.alertMedia {
    height: 1.5rem;
    width: 1.5rem;
  }
}
