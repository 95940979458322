@import '../ThemeProvider/ColorDefinitions.module.scss';
:root {
  --icc-red: #bc202e;
  --icc-orange: #f58220;
  --icc-yellow: #f9c93a;
  --icc-green: #94b042;
  --icc-blue: #3e91c5;
  --icc-blue-hover: #285d7e;
  --ms-purple: #5c2d91;
  --icc-brown: #4e3323;
  --icc-white: #fff;
  --icc-gray: #676767;
  --icc-darkGray: #4b4b4b;
  --icc-black: #393938;
  --icc-list-secondary: #eaeaea;
  --versionNumber: #dedede;
  --ms-purpleLight-hover: #b4a0ff;
  --icc-blue-disabled: blue;
}

.FormControlPlaintext {
  color: $midLightBlack;
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: #eeeeee;
  border: solid transparent;
  border-bottom: 1px solid lighten($color: #676767, $amount: 20%);
  border-width: 1px 0;
}

.MuiIconButtonColorSecondary {
  color: $primary-blue !important;
}

.btnIcc {
  background-color: var(--icc-blue) !important;
  border-color: var(--icc-blue) !important;
  color: var(--icc-white) !important;
  text-transform: none !important;
}

.btnIcc:hover {
  background-color: var(--icc-blue-hover) !important;
  border-color: var(--icc-blue-hover) !important;
  color: var(--icc-white) !important;
}

.btnIcc:disabled {
  pointer-events: none;
  background-color: var(--icc-blue-hover) !important;
  border-color: var(--icc-blue-hover) !important;
  color: var(--icc-gray) !important;
}

.title {
  color: var(--icc-black);
  display: block;
  font-weight: 500;
  font-size: 22px;
}
.titleSub {
  display: block;
  min-width: 100%;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}
.titlePrompt {
  font-size: 12px;
  text-align: center;
  min-width: 100%;
  font-weight: 500;
}

.email {
  display: block;
  width: 100%;
  min-width: 100%;
  border: none;
  border-bottom: 1px solid lighten($color: #676767, $amount: 30%);
  &:disabled {
    background-color: lighten($color: #676767, $amount: 50%);
  }
}

.inputLabel {
  font-weight: 500;
  font-size: 16px;
  padding-left: 0px;
  margin-left: 0px;
  margin-bottom: 4px;
}

.helpText {
  font-size: 10px;
  color: lighten($color: #676767, $amount: 20%);
  margin: 0;
}

.enabledInputs {
  display: block;
  outline-color: none;
  color: var(--icc-darkGray);
  outline: none;
  width: 100%;
  border: none;
  border-bottom: 1px solid lighten($color: #676767, $amount: 30%);
  &:active {
    border: none;
    outline: none;
    outline-color: none;
    border-bottom: 1px solid #3e91c5;
    box-shadow: none;
  }
}

.countryBox {
  background-color: lighten($color: #676767, $amount: 50%);
  min-width: 28px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.floatRight {
  float: right;
}
