@import '../ThemeProvider/mixins.scss';
@import '../ThemeProvider/_listItem.scss';

.root {
  margin-bottom: 0;
  cursor: pointer;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 1px;
  border-radius: 0;
  border-color: lightgray;
  border-left-width: 10px;
  border-left-color: lightgray;
  border-left-style: solid;
  position: relative;
  margin-top: 0.75em;

  .trendingIcon {
    position: absolute;
    right: 20px;
    font-size: 20px;
  }

  .badge {
    width: 10px;
    height: 10px;
    background-color: rgb(3, 160, 3);
  }

  /*Mix normal css whoa!!!*/
  /*.root:global.MuiPaper-elevation1 {
        box-shadow: none;
    }*/
  &.selected {
    @include listItemSelected(true);
    margin-bottom: 1px;
  }

  .content {
    padding-bottom: 0;
    position: relative;
  }

  svg.icon {
    @include themeMod(color, actionIconColor);
  }

  svg.blueIcon {
    color: $primary-blue;
  }

  .actions {
    float: none;
    padding-bottom: 0px;
  }

  .issueStatus {
    width: 100%;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .typeDescr {
    float: right;
    font-weight: bold;
  }

  .resolvedMessage {
    font-size: 14px;
    float: left;
  }
}

.orgName {
  font-size: 0.7rem;
  color: $labelGrey;
  padding-top: 0px;
  padding-bottom: 0px;
}
.orgCard {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
