@import '../ThemeProvider/mixins.scss';

.count {
  color: '#3E91C5';
  padding-right: 5;
  font-weight: 'bold';
}

.compactCount {
  position: 'absolute';
  top: 0;
  right: 2;
}

.pointer {
  cursor: pointer;
}

.compactListItem {
  display: 'block';
  text-align: 'left';
  padding-left: 10px;
  padding-right: 0px;
}

.selected {
  outline-style: solid;
  outline-color: $primary-blue;
  outline-width: 2px;
}
.icon {
  color: $primary-blue;
}
.listItem:hover .icon,
.listItem:hover svg {
  filter: brightness(0.7);
}
