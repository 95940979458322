$themes: ();
$themeValues: ();

@mixin themeMod($property, $key, $inlineExtra: null, $postExtra: null) {
  @each $theme in $themes {
    @if (themeExists($key, $theme)) {
      $value: getThemeValue($key, $theme);

      :global(.Ico_Issue_ThemeProvider_#{$theme}) & {
        // @at-root .#{$theme} #{&} { // if you aren't using css-modules, use this instead of the :global line above
        #{$property}: #{$inlineExtra}
          #{$value}
          #{$postExtra}; //fallback for browsers that shouldn't exist anymore
        #{$property}: #{$inlineExtra}
          var(--theme-#{$theme}-#{$key}, #{$value})
          #{$postExtra};
      }
    } @else {
      @error "Property #{$key} doesn't exist in #{$theme}";
    }
  }
}

@mixin theme($property, $key, $inlineExtra: null, $postExtra: null) {
  @each $theme in $themes {
    @if (themeExists($key, $theme)) {
      $value: getThemeValue($key, $theme);

      .Ico_Issue_ThemeProvider_#{$theme} & {
        // @at-root .#{$theme} #{&} { // if you aren't using css-modules, use this instead of the :global line above
        #{$property}: #{$inlineExtra}
          #{$value}
          #{$postExtra}; //fallback for browsers that shouldn't exist anymore
        #{$property}: #{$inlineExtra}
          var(--theme-#{$theme}-#{$key}, #{$value})
          #{$postExtra};
      }
    } @else {
      @error "Property #{$key} doesn't exist in #{$theme}";
    }
  }
}
/*
  @mixin generateThemeMappings(themeName: string, themeMap: map)
  helper function for generating list of theme variables and adding to existing map.
  This will add a new theme to the themes array and the theme color list.
*/
@mixin generateThemeMappings($themeName, $newThemeMap) {
  //creates/adds to list of theme names
  $themes: append($themes, $themeName, $separator: comma) !global;

  @each $key, $value in $newThemeMap {
    //adds new themeKey if doesn't exist
    @if not map-has-key($themeValues, $key) {
      $themeValues: map-merge(
        $themeValues,
        (
          $key: (
            $themeName: $value,
          ),
        )
      ) !global;
    }
    //adds to existing key map
    @else {
      $existingKeyMap: map-get($themeValues, $key);
      //if theme variable doesn't exist, add it
      @if not map-get($existingKeyMap, $themeName) {
        $newKeyMap: map-merge(
          $existingKeyMap,
          (
            $themeName: $value,
          )
        );
        $themeValues: map-merge(
          $themeValues,
          (
            $key: $newKeyMap,
          )
        ) !global;
      }
    }
  }
}

/** this section generates a theme map for each theme **/
@import './Themes/light.scss';
@include generateThemeMappings(light, $light);
@import './Themes/dark.scss';
@include generateThemeMappings(dark, $dark);
/** end theme generation **/

/*
  @mixin generateThemeVariables
  Auto-generates the entire list of theme variables for use in var() statements.
  Really should only be called in the html selector at the app root.
*/
@mixin generateThemeVariables {
  @each $key, $val in $themeValues {
    @each $theme in $themes {
      @if (themeExists($key, $theme)) {
        --theme-#{$theme}-#{$key}: #{getThemeValue($key, $theme)};
      }
    }
  }
}

@mixin transition($x...) {
  -webkit-transition: $x;
  -moz-transition: $x;
  -ms-transition: $x;
  -o-transition: $x;
  transition: $x;
}
