.root {
  height: 100%;

  :global(.react-pdf__Document),
  :global(.react-pdf__Page) {
    width: auto !important;
    height: 100% !important;
    margin: auto;
  }

  :global(.react-pdf__Page__canvas) {
    width: 80% !important;
    height: 100% !important;
    margin: auto;
  }

  :global(.btn) {
    margin-right: 10px;
  }

  .pageNumbers {
    position: relative;
    top: 5px;
    color: white;

    svg {
      color: white;
      margin-left: 10px;
    }
  }

  .footer {
    padding-left: 15px;
  }
}

.pdfContainer {
  height: 100% !important;
  width: 100% !important;
}
