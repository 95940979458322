@import 'ThemeProvider/ColorDefinitions.module.scss';

.tiptap-toolbar button[class^='ql-'] {
  background: none;
  outline: none;
  border-style: none;
}

.tiptap-toolbar button[class^='ql-'].ql-active,
.tiptap-toolbar button[class^='ql-'].ql-active > svg {
  color: #eb9316;
}

.tiptap-editor {
  padding: 5px 15px !important;
}

/* Limit editor height */
.tiptap-editor-max100 {
  max-height: 100px;
}

.tiptap-editor-max200 {
  max-height: 200px;
}

.tiptap-editor-max300 {
  max-height: 300px;
}

.tiptap-container {
  font-size: 16px;
}

.ico-issuemanagement-tiptap-paragraph {
  padding: 0px;
  margin-bottom: 0px;
}

.toolbar button {
  background: none;
  outline: none;
  border: none;
  margin: 0 5px;
  font-size: 16px;
  cursor: pointer;
  color: $midBlack;
}

.toolbar select {
  margin-right: 10px;
  cursor: pointer;
}

.toolbar button.active {
  font-weight: bold;
  color: var(--icc-blue);
}

.toolbar button svg {
  width: 20px;
  height: 20px;
}

.tiptap-editor {
  padding: 5px 15px;
  font-size: 16px;
}

ol {
  margin: 0;
  padding-left: 20px;
}

ul {
  margin: 0;
  padding-left: 20px;
}

p,
ol,
ul {
  margin-bottom: 0.5em;
  line-height: 1.5;
}
