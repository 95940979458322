.dialogContent {
  min-width: 200px;
}

.dialog {
  min-width: 200px;
  min-height: 200px;
}

.progress {
  min-height: 100px;
  height: 100%;
}
