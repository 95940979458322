/****Colors***/
$primary-blue: #3e91c5;
/*$primary-blue: #4e91c6;*/
$primary-darkBlue: darken($primary-blue, 10%);
$royalDarkBlue: #003e6b;
$royalDarkBlueActive: #336589;
$royalBlue: #0a558c;
$lightBlue: #d9e2ec;
$secondary-orange: #f58220;
$secondary-darkOrange: darken($secondary-orange, 10%);
$midBlack: #323130;
$midLightBlack: #495057;
$white: white;
$black: black;
$lightGray: #d3d3d3;
$midGray: #959595;
$fadedGray: #e6e6e6;
$danger: #dc3545;
$labelGrey: rgba(0, 0, 0, 0.54);
$backgroundBlue: rgb(64, 148, 195);
$iccBlack: #393938;
$msTeamsPurple: #7b83eb;

$primary-light: #6c91e2;
$primary-default: #395fa3;
$primary-dark: #203864;
$primary-darker: #152440;

$opsSync-light: #7dfc9b;
$opsSync-default: #00b050;
$opsSync-dark: #006e2f;
$opsSync-darker: #005322;

$background-white: #ffffff;
$background-lighter: #f7f7f7;
$background-bluegrey-light: #eaecec;
$background-light: #e0e1dd;
$background-dark: #595959;
$background-darker: #262626;

$danger-light: #f65954;
$danger-default: #df2935;
$danger-darker: #9e1a23;

$text-grey: #595959;
$text-mid: #495057;
$text-dark: #262626;

:export {
  primary-blue: $primary-blue;
  primary-darkBlue: $primary-darkBlue;
  royalDarkBlue: $royalDarkBlue;
  primaryControlDarker: $royalDarkBlue;
  backgroundBlue: $backgroundBlue;
  iccBlack: $iccBlack;
}

/***Button Colors***/
$btn-color-primary: $primary-blue;
$btn-color-secondary: $secondary-orange;
$btn-color-primaryDark: $primary-darkBlue;
$btn-color-secondaryDark: $secondary-darkOrange;

/****Input Colors****/
$required-input-bg-color: #fff8dc;

/****Icon Colors*****/
$icon-blue: #186faf;
$icon-lightBlue: #9fb3c8;
$action-icon-color: $icon-lightBlue;
$primary-icon-color: $icon-blue;

/****Color Map****/
$allColors: (
  lightBlue: $lightBlue,
  primary-blue: $primary-blue,
  primary-darkBlue: $primary-darkBlue,
  royalBlue: $royalBlue,
  secondary-orange: $secondary-orange,
  secondary-darkOrange: $secondary-darkOrange,
  black: $black,
  lightGray: $lightGray,
  fadedGray: $fadedGray,
  labelGrey: $labelGrey,
  white: $white,
  midBlack: $midBlack,
  midLightBlack: $midLightBlack,
  iconLightBlue: $icon-lightBlue,
  iconBlue: $icon-blue,
  linkHoverBlue: $primary-darkBlue,
  royalDarkBlue: $royalDarkBlue,
  danger: $danger,
  background-lighter: $background-lighter,
);
