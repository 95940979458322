.headerBack {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 10px;
}

.dialogContent {
  display: flex;
  flex-direction: column;
  width: 500px;

  @media (max-width: 950px) {
    width: 100%;
  }
}
.templateListItemSelected {
  background-color: var(--icc-blue);
  color: white;
}

.templateListItem {
  height: 30px;
  align-items: center;
  padding: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  width: 100%;
  &:nth-of-type(odd) {
    background-color: lightgray;
  }
}

.appendOverwriteSection {
  padding-bottom: 5px;
  border-bottom: 1px solid black;
}

.submitButtonContainer {
  display: flex;
  justify-content: flex-end;
}
